import { CardContent, Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TableCellData } from "../../components/common/formStyle/Table";
import MainCard from "../../components/common/MainCard";

export const SubCardWeapper = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0),
  height: "auto",

  [theme.breakpoints.up("md")]: {
    height: "222px",
  },
}));

export const SubTableCardWeapper = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0),
  height: "auto",

  [theme.breakpoints.up("md")]: {
    height: "400px",
  },
}));

export const SubTableCardWeapperNew = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(0),
  height: "auto",

  [theme.breakpoints.up("md")]: {
    height: "400px",
    overflow: "auto",
  },
}));

export const SubCardTopInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: '10px',
}));

export const SubCardMainInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: " center  ",
  flexWrap: "wrap",
  margin: theme.spacing(2, 0),
  gap: theme.spacing(1),

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(6, 0),
  },
}));

export const MainCardBox = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",

  [theme.breakpoints.up("md")]: {
    backgroundColor: theme.palette.primary.color1,
  },
}));

export const HelloText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  position: "relative",
  bottom: "50px",

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Header_semibold_32,
    bottom: 0,
  },
}));

export const TableTitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_20,
  },
}));

//
export const ViewPageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 5, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 10, 5, 10),
  },
}));
