import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../../../components/common/SuccessModal";

const EmailVerificationSuccess = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (!state) {
      navigate("/signup");
    }
  }, []);

  const handleClick = () => {
    navigate("/password-setup", { state: state });
  };
  return (
    <>
      <SuccessModal
        successMsg="Email verification "
        success="successful"
        subTitle="You have successfully verified your mailing address"
        btnName="proceed"
        handleClick={handleClick}
      />
    </>
  );
};

export default EmailVerificationSuccess;
