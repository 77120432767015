import { AppBar, Box, Dialog, IconButton, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/Logo.svg";
import NavSection from "./NavSection";
import { NavConfig } from "./NavConfig";
import MyAccount from "./MyAccount";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 100;
const APPBAR_DESKTOP = 93;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  minHeight: APPBAR_MOBILE,
  zIndex: 99,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
  },

  [theme.breakpoints.up("md")]: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const BoxWrap = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  padding: theme.spacing(2, 0),

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 12),
  },
}));

const MainStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

DashboardNav.propTypes = {
  onOpenDashboardSidebar: PropTypes.func,
};

export default function DashboardNav({ onOpenDashboardSidebar }) {
  var NavConfigure = NavConfig();
  const theme = useTheme();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const isActiveRoot = useLocation();

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <BoxWrap sx={{}}>
        <MainStyle>
          <Box component="img" src={Logo} alt="Logo" width={144} height={58} />

          <Stack direction="row" alignItems="center" gap={3}>
            <NavSection navConfig={NavConfigure} />
            <MyAccount />
          </Stack>
        </MainStyle>
      </BoxWrap>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box component="img" src={Logo} alt="Logo" width={94} height={41} />

          <Stack direction="row" alignItems="center">
            {isActiveRoot.pathname === "/dashboard" && (
              <IconButton
                onClick={onOpenDashboardSidebar}
                sx={{
                  display: { lg: "none" },
                }}
              >
                <KeyboardArrowDownIcon
                  sx={{ fill: theme.palette.orange.main }}
                  fontSize="large"
                />
              </IconButton>
            )}

            <IconButton
              {...{
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog
          {...{
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          PaperProps={{
            sx: { width: "100%" },
          }}
        >
          <Stack direction="column" alignItems="center" gap={3}>
            <Box
              py={4}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #002E45",
              }}
            >
              <MyAccount />
            </Box>
            <NavSection navConfig={NavConfigure} />
          </Stack>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <RootStyle>
        <>{mobileView ? displayMobile() : displayDesktop()}</>
      </RootStyle>
    </>
  );
}
