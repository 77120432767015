// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.orange.main,
          textTransform: "capitalize !important",
          borderRadius: 12,
          color: theme.palette.common.white,
          ...theme.typography.Body_medium_16,
          // width: "-webkit-fill-available !important",

          "&:hover": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.orange.main,
            fill: theme.palette.orange.main,
            boxShadow: "none",
          },
        },

        sizeLarge: {
          height: 56,
          width: "max-content",
          textTransform: "initial",
        },
        containedPrimary: {
          boxShadow: "none",
        },
        containedSecondary: {
          boxShadow: "none",
        },

        outlined: {
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.orange.main}`,
          textAlign: "center",
          color: theme.palette.orange.main,
          // width: "-webkit-fill-available !important",
        },
      },
    },
  };
}
