import { Container, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import MainLogo from "../../assets/Logo.svg";

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 3,
  // display: "block",
  // background: theme.palette.primary.main,
  background: "transparent",
  width: "90vh",
  padding: theme.spacing(2, 0),
  // position: "fixed",

  [theme.breakpoints.up("sm")]: {
    top: theme.spacing(2),
    left: theme.spacing(14),
    padding: theme.spacing(0),
  },
}));

const Logo = () => {
  return (
    <>
      <LogoWrapper>
        <Box
          component="img"
          src={MainLogo}
          alt="Logo"
          sx={{ height: "44px", width: "128px", margin: "10px" }}
        />
      </LogoWrapper>
    </>
  );
};

export default Logo;
