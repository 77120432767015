// ----------------------------------------------------------------------

export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          background: 'rgba(27,29,33,0.6)',
          backdropFilter: 'blur(24px)',
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.trans20,
          borderRadius: '50%',
          height: '150px !important',
          width: '150px !important',
        },
      },
    },
  };
}
