// ----------------------------------------------------------------------

export function NavConfig() {
  const NavConfigure = [
    {
      title: "Transactions",
      path: "/dashboard",
    },
    {
      title: "View Plan",
      path: "/view-plans",
    },

    // {
    //   title: "Risk Indicators",
    //   path: "/risk-and-insight",
    // },
    {
      title: "Dashboard",
      path: "/dashboard-overview",
    },
  ];
  return NavConfigure;
}
