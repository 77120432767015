import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Slide,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AnimateButton from "../../../components/common/formStyle/AnimateButton";
import BackIcon from "../../../assets/BackIcon.svg";
// import { PermissionsBox, PermissionsDetailsBox } from "./PlansStyle";
import { Link as RouterLink } from "react-router-dom";
import StatementIcon from "../../../assets/Statement.svg";
import BalanceIcon from "../../../assets/Balance.svg";

import {
  FillButton,
  OutlineButton,
  StyledDivider2,
} from "../../../components/common/style";
import MainCard from "../../../components/common/MainCard";
import {
  TableCellData,
  TableCellHead,
  TableRows,
} from "../../../components/common/formStyle/Table";
import {
  MainTitle,
  TableDataWrapper,
  TotalCard,
  YearlyCard,
} from "./TabStytle";
import moment from "moment";
import TransactionModal from "../../../components/common/TransactionModal";

// animation
const Transition = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

function createData(Monthly, Amount) {
  return { Monthly, Amount };
}

//
const MonthlyIncome = ({
  open,
  handleCloseDialog,
  dashboardData,
  totalIncome,
  selectedType,
}) => {
  let [monthlyIncome, setMonthlyIncome] = useState([]);
  // let [totalIncome, setTotalIncome] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (dashboardData && dashboardData.length) {
      let getData = dashboardData.filter((item) => {
        return (
          item.aggregation_lvl === 1 &&
          item.tran_type === 'I' &&
          item.category_lvl1 === 'Income'
        );
      });
      if (getData.length) {
        const result = getData.reduce(
          (total, thing) => total + thing.tran_amount,
          0
        );
        // setTotalIncome(result || 0);
      }
      setMonthlyIncome(getData);
    }
  }, [open, dashboardData]);
  const handleClick = () => {};
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      sx={{
        '&>div:nth-of-type(3)': {
          justifyContent: 'flex-end',
          '&>div': {
            m: 0,
            borderRadius: '0px',
            width: '100%',
            maxHeight: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.light,
            padding: 0,

            [theme.breakpoints.up('lg')]: {
              width: '50%',
              padding: theme.spacing(5, 2, 5, 2),
            },
          },
        },
      }}
    >
      {open && (
        <>
          <DialogContent mb={2}>
            <Stack
              direction="row"
              alignItems="center"
              mb={2}
              sx={{ cursor: 'pointer' }}
              onClick={handleCloseDialog}
            >
              <Box>
                <Box component="img" src={BackIcon} alt="back" />
              </Box>
              <Typography
                variant="Body_medium_12"
                color="grey.lighter"
                ml={1.25}
              >
                Back
              </Typography>
            </Stack>

            {/*  */}
            <Box mb={4}>
              <MainTitle>Income </MainTitle>
            </Box>

            {/* <TableDataWrapper> */}
            <Box>
              <TableContainer>
                <Table
                  sx={{
                    overflowX: 'hidden',
                    minWidth: 300,
                    height: 'max-content',
                  }}
                >
                  <TableHead>
                    <TableRows>
                      <TableCellHead>Monthly</TableCellHead>
                      <TableCellHead align="center">
                        Confident Level
                      </TableCellHead>
                      <TableCellHead align="right">Amount</TableCellHead>
                    </TableRows>
                  </TableHead>
                  <TableBody>
                    {monthlyIncome?.map((row) => (
                      <TableRow
                        hover
                        sx={{ cursor: 'pointer' }}
                        key={row.tmonth}
                        onClick={() => {
                          setSelectedValue(row);
                          setIsOpen(true);
                        }}
                      >
                        <TableCellData>
                          {moment(row.tmonth).format('MMMM')}
                        </TableCellData>
                        <TableCellData align="center">
                          {row?.confidence_level
                            ? (row?.confidence_level * 100).toFixed(2)
                            : 0}
                          %
                        </TableCellData>
                        <TableCellData align="right">
                          ₦{' '}
                          {(row.tran_amount || 0).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCellData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TotalCard>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="Body_medium_20" color="orange.main">
                    Total{' '}
                  </Typography>
                  <Typography variant="Body_semibold_18">
                    ₦{' '}
                    {(
                      (totalIncome.length && totalIncome[0]?.[selectedType]) ||
                      0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{' '}
                  </Typography>
                </Stack>
              </TotalCard>
            </Box>

            {/* <YearlyCard>
                <Typography>Yearly</Typography>
                <StyledDivider2 />

                <Stack direction="column" gap={1.5}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2022
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 4,800,000.00
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2021
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 3,540,000.00
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="Body_medium_12" color="orange.main">
                      2020
                    </Typography>
                    <Typography variant="Body_medium_12">
                      ₦ 5,100,000.00
                    </Typography>
                  </Stack>
                </Stack>
              </YearlyCard> */}
            {/* </TableDataWrapper> */}
          </DialogContent>
          <TransactionModal
            isOpen={isOpen}
            selectedValue={selectedValue}
            setIsOpen={setIsOpen}
          />
        </>
      )}
    </Dialog>
  );
};

MonthlyIncome.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default MonthlyIncome;
