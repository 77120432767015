import {
  Autocomplete,
  Box,
  Card,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GetAxiosInstance from '../../components/common/api';
import { getUserData } from '../../components/common/common';
import MainCard from '../../components/common/MainCard';
import {
  BoxWrapper,
  CustomPaper,
  StyledDivider2,
} from '../../components/common/style';
import DashboardUINav from '../../components/Navbar/DashboardUINav/DashboardUINav';
import {
  DirectDebitWrapper,
  RAICard,
  RAICardInfoBox,
  SecondaryCard,
  StyledTableRow,
  StyledTableCellHead,
  StyledTableCellData,
  TableCard,
  StyledTableContainer,
  AmountText,
} from './RiskAndInsightStyle';
import moment from 'moment';
import Loader from '../../components/common/Loader';
import Flutterwave from '../../assets/flutterwave.png';
import Paystack from '../../assets/paystack.png';
import { setMonth } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { ML_SELECTION_AVAILABLE } from '../../consts/app.const';
import banksList from '../../util/bankList';
import TransactionAmountSpikeChart from './TransactionAmountSpikeChart';

function createData(Item, Percentage, Amount) {
  return { Item, Percentage, Amount };
}
const rows = [
  createData('Restaurant', '23%', '₦ 300,000.00'),
  createData('Telco', '10%', '₦ 180,000.00'),
  createData('Gambling', '10%', '₦ 180,000.00'),
  createData('Overdraft', '10%', '₦ 180,000.00'),
  createData('Loans', '10%', '₦ 180,000.00'),
  createData('Gas', '10%', '₦ 180,000.00'),
  createData('Nightlife', '10%', '₦ 180,000.00'),
  createData('Parties', '10%', '₦ 180,000.00'),
  createData('Hangouts', '10%', '₦ 180,000.00'),
  createData('Trips', '10%', '₦ 180,000.00'),
];

const monthRange = [
  { title: '1 Month', value: 1 },
  {
    title: '3 Months',
    value: 3,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '12 Months',
    value: 12,
  },
];
const accountIds = [
  {
    title: '0235087159',
    value: null,
  },
  {
    title: '02350871589',
    value: null,
  },
];
const typeOptions = [
  {
    title: 'Total',
    value: 'tran_amount_total_for_period',
  },
  {
    title: 'Monthly Average',
    value: 'tran_amount_monthly_average',
  },
];

const mlOptions = [
  {
    title: 'Text Based',
    value: false,
  },
  {
    title: 'Chat GPT',
    value: true,
  },
];
const RiskAndInsight = () => {
  let [data, setData] = useState();
  const location = useLocation();

  let [noSalaryMonths, setNoSalaryMonths] = useState();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [consent, setConsent] = useState();
  const [filletElement, setFilletElement] = useState([]);

  const [endDate, setEndDate] = useState();
  const [totalIncome, setTotalIncome] = useState([]);
  const [totalExpense, setTotalExpense] = useState([]);
  const [totalAffordability, setTotalAffordability] = useState([]);
  const [totalInFlow, setTotalInFlow] = useState([]);
  const [totalOutFlow, setTotalOutFlow] = useState([]);
  const [riskIndicators, setRiskIndicators] = useState([]);
  const [selectMonth, setSelectMonth] = useState();
  const [openExpenses, setOpenExpenses] = useState(false);
  const [riskResponse, setRiskResponse] = useState({});
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [selectAccount, setSelectAccount] = useState(null);
  const [grantTotal, setGrantTotal] = useState();
  const [dateToValueData, setDateToValueData] = useState('');
  const [selectedML, setSelectedML] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedType, setSelectedType] = useState(
    'tran_amount_total_for_period'
  );
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [selectedConsent, setSelectedConsent] = useState({});

  const handleClickOpenExpensesDialog = () => {
    setOpenExpenses(true);
  };
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const [openAffordability, setOpenAffordability] = useState(false);
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };
  useEffect(async () => {
    setBankList(await banksList());
  }, []);
  useEffect(async () => {
    if (bankList.length) {
      setLoader(true);
      let userData = getUserData();
      await GetAxiosInstance(`orchestrator/get-consent`, 'get').then((res) => {
        if (res.data?.codes === 200) {
          if (res.data?.data?.consents?.length) {
            let accountNumberList = [];
            res?.data?.data?.consents.map((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === 'affordability_basic' ||
                    item.code === 'affordability_full'
                ).length
              )
                if (v.accountNumber && v.dashboardAvailable) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                    icon: bankList.find((i) => i.bankName === v.bankName)?.icon,
                    bankName: bankList.find((i) => i.bankName === v.bankName)
                      ?.bankName,
                  });
                }
            });
            if (!accountNumberList.length) {
              navigator('/statement');
            }
            accountNumberList.push({
              title: 'CONSOLIDATED',
              value: 'all',
              icon: '',
              bankName: '',
            });
            setAccountsNumberList(accountNumberList);
            setConsent(res.data?.data?.consents);
          }
        } else {
          setLoader(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      });
    }
  }, [bankList]);
  useEffect(() => {
    setLoader(true);
    let endDate = moment().format('YYYY-MM-DD ');

    let selectedProduct = consent?.find(
      (item) => item.accountNumber === selectAccount
    );
    if (selectedProduct && selectAccount?.length) {
      endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      );
      let dateRangArray = [
        {
          startDate: selectedProduct?.consent?.data?.dateFrom,
          endDate: selectedProduct?.consent?.data?.dateTo,
        },
      ];
      calculateDateRang(dateRangArray, setSelectedDateRang);
      setSelectedConsent(selectedProduct);
    } else {
      let dateRangArray = consent?.map((v) => {
        return {
          startDate: v.consent?.data?.dateFrom,
          endDate: v.consent?.data?.dateTo,
        };
      });
      let allEndDate = consent?.map((v) => v.consent?.data?.dateTo);
      let maxEndDate =
        allEndDate?.length &&
        allEndDate.reduce(function (a, b) {
          return a > b ? a : b;
        });
      calculateDateRang(dateRangArray, setSelectedDateRang);
      endDate = moment(maxEndDate).format('YYYY-MM-DD');
      setEndDate(endDate);
    }
  }, [selectAccount, consent, selectMonth]);
  useEffect(() => {
    let noSalaryMonths = '';
    riskResponse?.noincome?.length &&
      riskResponse?.noincome.forEach((item, index) => {
        let separateBy = '';
        if (index === 0) {
          separateBy = '';
        } else if (index === data?.noincome.length - 1) {
          separateBy = ' and ';
        } else {
          separateBy = ', ';
        }
        noSalaryMonths =
          noSalaryMonths + separateBy + moment(item.tmonth).format('MMMM');
      });
    setNoSalaryMonths(noSalaryMonths);
  }, [riskResponse]);
  useEffect(() => {
    let dataArray = [];
    let userData = getUserData();

    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    setDateToValueData(dateToValue);
    let dashboardPayload = {
      userId: userData?.id,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === 'all' ? null : selectAccount,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };

    if (
      dashboardPayload?.userId &&
      dashboardPayload?.date_to &&
      dashboardPayload?.n_months
    ) {
      GetAxiosInstance(
        'orchestrator/get-dashboard',
        'post',
        dashboardPayload
      ).then((res) => {
        if (res?.data?.codes === 200) {
          // setLoader(false);
          // if (res?.data?.data?.monthly?.length) {
          setDashboardData(res?.data?.data?.monthly);
          // } else {
          // setLoader(false);
          // }
        } else {
          setDashboardData([]);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
    let payload = {
      user_id: userData?.id,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === 'all' ? null : selectAccount,
      tran_threshold: 0,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };
    if (payload.user_id && payload.date_to && payload.n_months) {
      GetAxiosInstance('orchestrator/get-risk', 'post', payload).then((res) => {
        if (res?.data?.codes === 200) {
          setLoader(false);
          const result = res.data?.data?.transaction_threshold.reduce(
            (total, thing) => total + thing[selectedType],
            0
          );
          setGrantTotal(result);
          setRiskResponse(res.data?.data);
        } else {
          setRiskResponse([]);
          setGrantTotal();
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
  }, [selectedDateRang, endDate, selectedML]);
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);

      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        console.log('selectedIcon', selectedIcon);
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList]);
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />
      <BoxWrapper mt={5}>
        {!loader ? (
          <>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: { lg: 5, xs: 15 } }}
            >
              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ flexGrow: 1, mt: { sm: 0, xs: 5 }, mr: 1.5 }}>
                <Grid container gap={2} justifyContent="flex-end">
                  {ML_SELECTION_AVAILABLE && (
                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        TestML Model
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={mlOptions}
                        getOptionLabel={(option) => option.title || ''}
                        PaperComponent={CustomPaper}
                        value={mlOptions?.find(
                          (item) => item.value === selectedML
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectedML(value?.value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Account Number
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={accountsNumberList || []}
                      getOptionLabel={(option) => option.title || ''}
                      PaperComponent={CustomPaper}
                      disableClearable
                      value={accountsNumberList?.find(
                        (item) => item.value === selectAccount
                      )}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={props['data-option-index']}
                          component="li"
                          name={option.bankName}
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        >
                          <img
                            loading="lazy"
                            name={option.bankName}
                            width="35"
                            style={{ borderRadius: '50%' }}
                            src={option.icon}
                            alt=""
                          />
                          {option.title}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {selectedBankLogo && (
                                  <Box
                                    component="img"
                                    src={selectedBankLogo}
                                    alt=""
                                    sx={{
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectAccount(value?.value || null);
                        setSelectedBankLogo(value?.icon);
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Type
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={typeOptions}
                      getOptionLabel={(option) => option.title || ''}
                      PaperComponent={CustomPaper}
                      value={typeOptions.find(
                        (item) => item.value === selectedType
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectedType(value.value);
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Month
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={selectedDateRang}
                      getOptionLabel={(option) =>
                        moment(option).format("MMMM - YYYY")
                      }
                      PaperComponent={CustomPaper}
                      value={selectedDateRang.find(
                        (item) => item === selectedEndDate
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newDate) => {
                        setSelectedEndDate(
                          newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                        );
                      }}
                    />
                  </Grid> */}
                  <Grid item md={3} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Filter Period
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={[...filletElement, ...selectedDateRang]}
                      getOptionLabel={(option) => {
                        if (
                          moment(option?.title).format('MMMM - YYYY') !==
                          'Invalid date'
                        ) {
                          return moment(option?.title).format('MMMM - YYYY');
                        } else {
                          return option?.title;
                        }
                      }}
                      PaperComponent={CustomPaper}
                      value={
                        selectMonth
                          ? [...filletElement, ...selectedDateRang].find(
                              (item) => {
                                if (selectedEndDate) {
                                  return item.value === selectedEndDate;
                                } else {
                                  return item.value === selectMonth;
                                }
                              }
                            )
                          : filletElement[filletElement.length - 1]
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newData) => {
                        setSelectMonth(newData?.value);
                        setSelectedEndDate(newData?.value);
                        // if (e.target.getInnerHTML()) {
                        //   let selected = filletElement.find(
                        //     (item) => item.title === e.target.getInnerHTML()
                        //   );
                        //   setSelectMonth(selected.value);
                        // }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <Box sx={{ flexGrow: 1, mt: 5 }}>
              <Grid container spacing={3.5}>
                <Grid item md={5} sm={12} xs={12}>
                  <TableCard>
                    <StyledTableContainer>
                      <Table
                        sx={{
                          minWidth: 350,
                        }}
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCellHead>Item</StyledTableCellHead>
                            <StyledTableCellHead>
                              Percentage
                            </StyledTableCellHead>
                            <StyledTableCellHead>Amount</StyledTableCellHead>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {riskResponse?.transaction_threshold?.map((row) => (
                            <TableRow hover key={row.Item}>
                              <StyledTableCellData>
                                {row.category_lvl2}
                              </StyledTableCellData>
                              <StyledTableCellData>
                                {parseFloat(
                                  row.tran_amount_percentage_of_income * 100
                                ).toFixed(2)}
                                %
                              </StyledTableCellData>
                              <StyledTableCellData>
                                ₦{' '}
                                {(row[selectedType] || 0).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}{' '}
                              </StyledTableCellData>
                            </TableRow>
                          ))}
                          <TableRow hover>
                            <StyledTableCellData>Total</StyledTableCellData>
                            <StyledTableCellData></StyledTableCellData>
                            <StyledTableCellData>
                              ₦{' '}
                              {(grantTotal || 0).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}{' '}
                            </StyledTableCellData>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </TableCard>
                </Grid>

                <Grid item md={7} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} sm={6} xs={12}>
                      <RAICard>
                        <Stack
                          direction="row"
                          alignItems="center"
                          mb={3}
                          spacing={1}
                        >
                          <Typography
                            variant="Body_medium_16"
                            color="orange.main"
                          >
                            Income changes over period
                          </Typography>
                        </Stack>

                        <RAICardInfoBox>
                          {/*<Typography variant="Body_light_14">
                          Salary payment {riskResponse?.incomerisk?.movement}{' '}
                          from
                            </Typography>*/}
                          <AmountText>
                            ₦{' '}
                            {(
                              riskResponse?.incomerisk?.oldvalue || 0
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{' '}
                          </AmountText>
                          <Typography variant="Body_light_14">
                          {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                          </Typography>
                        </RAICardInfoBox>

                        <Stack direction="row" alignItems="center">
                          <Typography variant="" color="orange.main">
                            To
                          </Typography>
                          <Divider />
                        </Stack>

                        <RAICardInfoBox>
                          <AmountText>
                            ₦{' '}
                            {(
                              riskResponse?.incomerisk?.newvalue || 0
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{' '}
                          </AmountText>
                          <Typography variant="Body_light_14">
                          {selectedType === 'tran_amount_monthly_average' ? 'Monthly Average' : 'Total'}
                          </Typography>
                        </RAICardInfoBox>
                      </RAICard>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <RAICard>
                        <Stack
                          direction="row"
                          alignItems="center"
                          mb={3}
                          spacing={1}
                        >
                          <Typography
                            variant="Body_medium_16"
                            color="orange.main"
                          >
                            Largest Funds Inflow/Outflow
                          </Typography>
                        </Stack>

                        <RAICardInfoBox>
                          <Typography variant="Body_light_14">
                            The largest Inflow transaction over period
                          </Typography>
                          <AmountText>
                            ₦{' '}
                            {(
                              riskResponse?.top_transactions?.inflow_top5?.[0]
                                ?.tran_amount || 0
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{' '}
                          </AmountText>
                        </RAICardInfoBox>

                        <RAICardInfoBox>
                          <Typography variant="Body_light_14">
                            The largest Outflow transaction over period
                          </Typography>
                          <AmountText>
                            ₦{' '}
                            {(
                              riskResponse?.top_transactions?.outflow_top5?.[0]
                                ?.tran_amount || 0
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{' '}
                          </AmountText>
                        </RAICardInfoBox>
                      </RAICard>
                    </Grid>

                    <Grid item md={4} sm={6} xs={12}>
                      <SecondaryCard>
                        <Stack
                          alignItems="flex-start"
                          direction="column"
                          justifyContent="space-between"
                          gap={2}
                        >
                          <Typography
                            variant="Body_medium_16"
                            color="orange.main"
                          >
                            No Income
                          </Typography>

                          <Stack direction="column">
                            {/* <Typography variant="Body_light_14">
                            no salary payment /
                          </Typography> */}
                            {noSalaryMonths && (
                              <Typography variant="Body_light_14">
                                {noSalaryMonths}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </SecondaryCard>
                    </Grid>
                    {parseInt(riskResponse?.direct_debit_mandates) > 0 && (
                      <Grid item md={8} sm={6} xs={12}>
                        <SecondaryCard>
                          <DirectDebitWrapper>
                            <Typography
                              variant="Body_semibold_18"
                              color="orange.main"
                            >
                              Direct Debit Mandates
                            </Typography>
                            <Stack
                              direction="column"
                              alignItems="end"
                              flexWrap="wrap"
                            >
                              <Typography variant="Body_light_14">
                                High monthly direct debits of
                              </Typography>
                              <Typography variant="Body_semibold_32">
                                ₦ 200,000.00
                              </Typography>

                              <Stack
                                direction="row"
                                gap={2}
                                mt={2}
                                alignItems="center"
                                flexWrap="wrap"
                              >
                                <Typography
                                  variant="Body_medium_14"
                                  color="orange.main"
                                >
                                  via
                                </Typography>
                                <Box component="img" src={Paystack} alt="..." />
                                <Box
                                  component="img"
                                  src={Flutterwave}
                                  alt="..."
                                />
                              </Stack>
                            </Stack>
                          </DirectDebitWrapper>
                        </SecondaryCard>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TransactionAmountSpikeChart
                    risk_spikes={riskResponse?.risk_spikes}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                opacity: '0.5',
              }}
              my={1}
            >
              Data Fetch from{' '}
              {moment(
                moment(dateToValueData?.title).format('MMMM - YYYY') !==
                  'Invalid date'
                  ? dateToValueData?.value
                  : endDate
              )
                .subtract(
                  moment(dateToValueData?.title).format('MMMM - YYYY') !==
                    'Invalid date'
                    ? 1
                    : selectMonth ||
                        filletElement[filletElement.length - 1].value,
                  'month'
                )
                .format('DD/MM/YYYY')}{' '}
              to{' '}
              {moment(
                moment(dateToValueData?.title).format('MMMM - YYYY') !==
                  'Invalid date'
                  ? dateToValueData?.value
                  : endDate
              ).format('DD/MM/YYYY')}
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </BoxWrapper>
    </>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange?.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }

      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });
    setSelectedDateRang(uniqueDates);
  });
}
export default RiskAndInsight;
