/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  FormInputWrapper,
} from "../AuthStyle";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import InputLabel from "../../../components/common/formStyle/InputLabel";

import AnimateButton from "../../../components/common/formStyle/AnimateButton";
import { toast } from "react-toastify";
import getAxiosInstance from "../../../components/common/api";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------

export function Confirm() {
  let { state } = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();

  const [otp, setOtp] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [otpError, setOtpError] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [cnfPasswordError, setCnfPasswordError] = useState("");
  const [loading, setLoading] = useState();
  useEffect(() => {
    if (!state) {
      navigate("/confirm");
    }
  }, []);
  const onSubmitFuc = (e) => {
    
    if (!otp) {
      setOtpError("please enter Otp");
      return;
    }
    if (!oldPassword) {
      setOldPasswordError("Please enter old Password");
      return;
    }
    if (!newPassword) {
      setNewPasswordError("Please enter new Password");
      return;
    }
    if (!cnfPassword) {
      setCnfPasswordError("Please confirm new Password");
      return;
    }
    if (newPassword!==cnfPassword) {
      toast.error("both Password must be same");
      return;
    }


    setLoading(true);
    getAxiosInstance(
      `auth/changePassword`,
      "post",
      {
        email: state.email,
        otp: otp,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      { accessToken: state.accessToken }
    ).then((res) => {
      setLoading(false);
      if (res?.data?.codes === 200) {
        toast.success(res?.data?.message);
        navigate("/home");
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };

  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="form"
            autoComplete="off"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Change Password
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>OTP</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="OTP"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      setOtp(e.target.value);
                      setOtpError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    helperText={otpError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Old Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="old password"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setOldPasswordError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label  ' }}
                    hiddenLabel
                    helperText={oldPasswordError}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>New Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="new password"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setNewPasswordError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    helperText={newPasswordError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Confirm Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="confirm password"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      setCnfPassword(e.target.value);
                      setCnfPasswordError("");
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    helperText={cnfPasswordError}
                  />
                </Grid>
              </FormInputWrapper>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={onSubmitFuc}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Proceed
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
