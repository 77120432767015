import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
//

export const YearlyCard = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.color1,
  borderRadius: 12,
  width: "auto",
  height: "max-content",
  padding: theme.spacing(3),
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    width: 246,
  },
}));

export const TotalCard = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.color1,
  borderRadius: 12,
  maxWidth: "100%",
  padding: theme.spacing(3, 4, 3, 4),
  margin: theme.spacing(2, 0, 0, 0),
}));

export const TableDataWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export const MainTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_28,
  },
}));

//
export const Abc = styled(Box)(({ theme }) => ({}));
export const UserImage = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {},
}));
