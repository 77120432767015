import { Box, Typography, Button, Grid, useTheme } from "@mui/material";
import React from "react";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
} from "../Auth/AuthStyle";
import { Link as RouterLink } from "react-router-dom";

import Cancel from "../../assets/Cancel.svg";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { FillButton, OutlineButton } from "../../components/common/style";
import MainCard from "../../components/common/MainCard";
import {
  ButtonCard,
  ButtonWrapper,
  PlanDescCommon,
  PlanDescCommon1,
  CancelPlanDescWrapper,
  PlanStepTitle,
} from "./PlansStyle";
import { Stack } from "@mui/system";

const CancelPlan = (props) => {
  const theme = useTheme();
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="box"
            style={{
              maxWidth: 670,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlanStepTitle>The Impact to your service</PlanStepTitle>

              <PlanDescCommon>
                The service you recieve will be reduced to the basic plan, as we
                can no longer access your data. This means{" "}
              </PlanDescCommon>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 4, my: 6 }}
              >
                <CancelPlanDescWrapper>
                  <Box component="img" src={Cancel} alt="Cancel" />
                  <PlanDescCommon1>
                    We won’t be able to provide your bank data as part of your
                    mortgage/lending application process.
                  </PlanDescCommon1>
                </CancelPlanDescWrapper>

                <CancelPlanDescWrapper>
                  <Box component="img" src={Cancel} alt="Cancel" />
                  <PlanDescCommon1>
                    You will have to enter your financial information manually
                    if you want to begin using this service again.
                  </PlanDescCommon1>
                </CancelPlanDescWrapper>
              </Box>
            </Box>

            <ButtonCard>
              <Stack direction="column" alignItems="center" gap={1}>
                <Typography
                  my={3.5}
                  variant="Body_medium_14"
                  textAlign="center"
                >
                  Have you read through and wish to continue?
                </Typography>

                <ButtonWrapper>
                  <Box sx={{ width: "inherit" }}>
                    <AnimateButton>
                      <Button
                        sx={{ width: "-webkit-fill-available" }}
                        size="large"
                        component={RouterLink}
                        to="/view-plans"
                        type="submit"
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </AnimateButton>
                  </Box>

                  <Box sx={{ width: "inherit" }}>
                    <AnimateButton>
                      <Button
                        sx={{ width: "-webkit-fill-available" }}
                        size="large"
                        component={RouterLink}
                        type="submit"
                        variant="contained"
                        to="/confirm-cancellation"
                      >
                        I Understand
                      </Button>
                    </AnimateButton>
                  </Box>
                </ButtonWrapper>
              </Stack>
            </ButtonCard>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};

export default CancelPlan;
