import { Box, Typography, Button, Grid, useTheme, Stack } from "@mui/material";
import React from "react";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
} from "../Auth/AuthStyle";
import { Link as RouterLink } from "react-router-dom";

import Cancel from "../../assets/Cancel.svg";
import Bank2 from "../../assets/Bank2.png";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { FillButton, OutlineButton } from "../../components/common/style";
import MainCard from "../../components/common/MainCard";
import {
  ButtonCard,
  ButtonWrapper,
  ConfirmCancelDescWrapper,
  PlanDescCommon,
  PlanDescCommon1,
  PlanStepTitle,
  PlanTitleCommon1,
} from "./PlansStyle";

const ConfirmCancellation = (props) => {
  const theme = useTheme();
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="box"
            style={{
              maxWidth: 670,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlanStepTitle>Confirm Cancellation</PlanStepTitle>

              <PlanDescCommon>
                We are cancelling your permission for us to access the following
                data.
              </PlanDescCommon>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                mt: 5,
              }}
            >
              <Box component="img" src={Bank2} alt="Cancel" />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                my: { md: 6, xs: 3 },
              }}
            >
              <ConfirmCancelDescWrapper>
                <PlanTitleCommon1>Your accounts</PlanTitleCommon1>
                <PlanDescCommon1>
                  For you to use this service E-DocOnlice needs to access
                  information from your accounts at your bank.
                </PlanDescCommon1>
              </ConfirmCancelDescWrapper>

              <ConfirmCancelDescWrapper>
                <PlanTitleCommon1>Your balance</PlanTitleCommon1>

                <PlanDescCommon1>
                  For you to use this service E-DocOnlice needs to access
                  information from your accounts at your bank.
                </PlanDescCommon1>
              </ConfirmCancelDescWrapper>
            </Box>

            <ButtonCard>
              <Stack direction="column" alignItems="center" gap={1}>
                <Typography
                  my={3.5}
                  variant="Body_medium_14"
                  textAlign="center"
                >
                  Are you sure you want to cancel permission to access this
                  account?{" "}
                </Typography>

                <ButtonWrapper>
                  <Box sx={{ width: "inherit" }}>
                    <AnimateButton>
                      <Button
                        sx={{ width: "-webkit-fill-available" }}
                        size="large"
                        component={RouterLink}
                        to="/view-plans"
                        type="submit"
                        variant="outlined"
                      >
                        Deny
                      </Button>
                    </AnimateButton>
                  </Box>

                  <Box sx={{ width: "inherit" }}>
                    <AnimateButton>
                      <Button
                        sx={{ width: "-webkit-fill-available" }}
                        size="large"
                        type="submit"
                        variant="contained"
                        component={RouterLink}
                        to="/cancellation-complete"
                      >
                        I Confirm
                      </Button>
                    </AnimateButton>
                  </Box>
                </ButtonWrapper>
              </Stack>
            </ButtonCard>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};

export default ConfirmCancellation;
