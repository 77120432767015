import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import {
  ExpandListBtn,
  MainCardStyled,
  MobileBox,
  TableCellHead,
  TableWrapper,
  StyledTableCellData,
  StyledTableCellDataItem,
  PageWrapper,
} from "./CategoryInsightsStyle";
import ApexColumnChart from "./ApexColumnChart";
import DonutChart from "./DonutChart";
import { CustomPaper } from "../../components/common/style";
import SearchIcon from "@mui/icons-material/Search";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import GetAxiosInstance from "../../components/common/api";
import { getUserData } from "../../components/common/common";
import moment from "moment";
import Loader from "../../components/common/Loader";
import { toast } from "react-toastify";
import { ML_SELECTION_AVAILABLE } from "../../consts/app.const";
import banksList from '../../util/bankList';
function createData(Group, Income, Expect) {
  return { Group, Income, Expect };
}
const months = [
  { title: "1 Month", value: 1 },
  { title: "3 Months", value: 3 },
  { title: "6 Months", value: 6 },
  { title: "9 Months", value: 9 },
];

const monthRange = [
  {
    title: "1 Month",
    value: 1,
  },
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];
const accountIds = [
  {
    title: "0235087159",
    value: null,
  },
  {
    title: "02350871589",
    value: null,
  },
];
const typeOptions = [
  {
    title: "Total",
    value: "tran_amount_total_for_period",
  },
  {
    title: "Monthly Average",
    value: "tran_amount_monthly_average",
  },
];
const mlOptions = [
  {
    title: "Text Based",
    value: false,
  },
  {
    title: "Chat GPT",
    value: true,
  },
];

const CategoryInsights = () => {
  let navigate = useNavigate();
  let [selectMonth, setSelectMonth] = useState();
  const [filletElement, setFilletElement] = useState([]);

  let [apexIncomeData, setApexIncomeData] = useState([]);
  let [apexExpenseData, setApexExpenseData] = useState([]);
  let [apexMonth, setApexMonth] = useState([]);
  let [transactionGroup, setTransactionGroup] = useState([]);
  let [loader, setLoader] = useState(false);
  let [nameSearch, setNameSearch] = useState("");
  let [filterValue, setFilterValue] = useState([]);
  let [dashboardData, setDashboardData] = useState();
  const [consent, setConsent] = useState();
  const [selectAccount, setSelectAccount] = useState(null);
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [bankList, setBankList] = useState([]);
  const [selectedBankLogo, setSelectedBankLogo] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedDateRang, setSelectedDateRang] = useState([]);
  const [dateToValueData, setDateToValueData] = useState("");
  const [selectedML, setSelectedML] = useState(false);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedConsent, setSelectedConsent] = useState({});

  const [selectedType, setSelectedType] = useState(
    'tran_amount_total_for_period'
  );
  const [grantTotal, setGrantTotal] = useState();

  let [disposableIncome, setDisposableIncome] = useState({
    income: 0,
    expenses: 0,
    affordability: 0,
  });
  const [openAffordability, setOpenAffordability] = useState(false);
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };
  useEffect(async () => {
    setBankList(await banksList());
  }, []);
  useEffect(async () => {
    if (bankList.length) {
      setLoader(true);

      await GetAxiosInstance(`orchestrator/get-consent`, 'get').then((res) => {
        if (res.data?.codes === 200) {
          // setLoader(false);
          if (res.data?.data.consents.length) {
            let accountNumberList = [];
            res?.data?.data.consents.map((v) => {
              if (
                v.consent.data.products.filter(
                  (item) =>
                    item.code === 'affordability_basic' ||
                    item.code === 'affordability_full'
                ).length
              )
                if (v.accountNumber && v.dashboardAvailable) {
                  accountNumberList.push({
                    title: `XXXXXX${v.accountNumber.slice(-4)}`,
                    value: v.accountNumber,
                    icon: bankList.find((i) => i.bankName === v.bankName)?.icon,
                    bankName: bankList.find((i) => i.bankName === v.bankName)
                      ?.bankName,
                  });
                }
            });
            if (!accountNumberList.length) {
              navigate('/statement');
            }
            accountNumberList.push({
              title: 'CONSOLIDATED',
              value: 'all',
              icon: '',
              bankName: '',
            });
            setAccountsNumberList(accountNumberList);
          }
          setConsent(res.data?.data?.consents);
        } else {
          setLoader(false);
          toast.error(
            res?.response?.data?.message ||
              res.message ||
              'Something went wrong!'
          );
        }
      });
    }
  }, [bankList]);
  useEffect(() => {
    setLoader(true);
    let endDate = moment().format('YYYY-MM-DD ');

    let selectedProduct = consent?.find(
      (item) => item.accountNumber === selectAccount
    );
    if (selectedProduct && selectAccount?.length) {
      endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
        'YYYY-MM-DD'
      );
      let dateRangArray = [
        {
          startDate: selectedProduct?.consent?.data?.dateFrom,
          endDate: selectedProduct?.consent?.data?.dateTo,
        },
      ];
      calculateDateRang(dateRangArray, setSelectedDateRang);
      setSelectedConsent(selectedProduct);
    } else {
      let dateRangArray = consent?.map((v) => {
        return {
          startDate: v.consent?.data?.dateFrom,
          endDate: v.consent?.data?.dateTo,
        };
      });
      let allEndDate = consent?.map((v) => v.consent?.data?.dateTo);
      let maxEndDate =
        allEndDate?.length &&
        allEndDate.reduce(function (a, b) {
          return a > b ? a : b;
        });
      calculateDateRang(dateRangArray, setSelectedDateRang);
      endDate = moment(maxEndDate).format('YYYY-MM-DD');
      setEndDate(endDate);
    }
  }, [selectAccount, consent, selectMonth]);
  useEffect(() => {
    let responseValue = [];
    if (nameSearch) {
      responseValue = transactionGroup.filter((item) => {
        return (
          (item.category_lvl1 &&
            item?.category_lvl1
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase())) ||
          (item.category_lvl2 &&
            item?.category_lvl2
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase()))
        );
      });
    } else {
      responseValue = transactionGroup;
    }
    if (responseValue.length) {
      const result = responseValue.reduce(
        (total, thing) => total + thing[selectedType],
        0
      );
      setGrantTotal(result);
    } else {
      setGrantTotal(0);
    }
    setFilterValue(responseValue);
  }, [nameSearch, transactionGroup, selectedType]);
  useEffect(() => {
    let dataArray = [];
    let userData = getUserData();

    let data = selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });
    setFilletElement(dataArray);
    let dateToValue = selectMonth
      ? [...filletElement, ...selectedDateRang].find((item) => {
          if (selectedEndDate) {
            return item.value === selectedEndDate;
          } else {
            return item.value === selectMonth;
          }
        })
      : filletElement[filletElement.length - 1];
    setDateToValueData(dateToValue);
    let dashboardPayload = {
      userId: userData?.id,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === 'all' ? null : selectAccount,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };
    if (
      dashboardPayload?.userId &&
      dashboardPayload?.date_to &&
      dashboardPayload?.n_months
    ) {
      GetAxiosInstance(
        'orchestrator/get-dashboard',
        'post',
        dashboardPayload
      ).then((res) => {
        if (res?.data?.codes === 200) {
          // setLoader(false);

          // if (res?.data?.data?.data?.length) {
          setDashboardData(res?.data?.data?.monthly);
          // }
        } else {
          setDashboardData([]);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    }
    let payload = {
      user_id: userData?.id,
      date_to:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? dateToValue?.value
          : endDate,
      n_months:
        moment(dateToValue?.title).format('MMMM - YYYY') !== 'Invalid date'
          ? 1
          : selectMonth || dataArray[dataArray.length - 1]?.value,
      account_id: selectAccount === 'all' ? null : selectAccount,
      tran_threshold: 0,
      testMLModel: selectedML,
      accountType: selectedConsent?.accountType,
      consentId: selectedConsent?.consentId,
    };
    if (payload?.date_to && payload?.n_months) {
      GetAxiosInstance(
        'orchestrator/get-category-insights',
        'post',
        payload
      ).then((res) => {
        if (
          res?.data?.codes === 200 &&
          res?.data?.data?.transaction_groups.length &&
          res?.data?.data?.disposable_income &&
          res?.data?.data?.monthly_transactions.length
        ) {
          setLoader(false);

          let result = res.data.data;
          let obj = {
            income: parseFloat(
              result.disposable_income.total_income
                ? result.disposable_income.total_income * 100
                : 0
            ).toFixed(2),
            expenses: parseFloat(
              result.disposable_income.total_expense
                ? result.disposable_income.total_expense * 100
                : 0
            ).toFixed(2),
            affordability: parseFloat(
              result.disposable_income.affordability || 0
            ).toFixed(2),
          };
          apexIncomeData = [];
          apexExpenseData = [];
          apexMonth = [];

          let incomeData = result?.monthly_transactions.filter((item) => {
            return (
              item.tran_type === 'I' &&
              item.category_lvl1 === 'Income' &&
              item.aggregation_lvl === 1
            );
          });
          let expenseData = result?.monthly_transactions.filter((item) => {
            return (
              item.tran_type === 'E' &&
              item.category_lvl1 === 'Expense' &&
              item.aggregation_lvl === 1
            );
          });
          if (incomeData.length) {
            incomeData.reverse().forEach((item) => {
              if (
                !apexIncomeData.includes(
                  moment(item?.tmonth).format('MMM-YYYY')
                )
              ) {
                apexIncomeData.push({
                  amount: item.tran_amount || 0,
                  month: moment(item?.tmonth).format('MMM-YYYY'),
                });
              }
            });
          }
          if (expenseData.length) {
            expenseData.reverse().forEach((item) => {
              if (
                !apexExpenseData.includes(
                  moment(item?.tmonth).format('MMM-YYYY')
                )
              ) {
                apexExpenseData.push({
                  amount: item.tran_amount || 0,
                  month: moment(item?.tmonth).format('MMM-YYYY'),
                });
              }
            });
          }
          if (apexExpenseData.length && apexIncomeData.length) {
            apexMonth = apexExpenseData.map((item) => {
              return item?.month;
            });
            apexIncomeData = apexIncomeData.map((item) => {
              return item?.amount;
            });
            apexExpenseData = apexExpenseData.map((item) => {
              return item?.amount;
            });
          }
          let transactions = result?.transaction_groups.filter((item) => {
            return (
              item.category_lvl1 !== null &&
              item.category_lv2 !== null &&
              item.aggregation_lvl === 2
            );
          });

          setApexIncomeData(apexIncomeData);
          setApexExpenseData(apexExpenseData);
          setApexMonth(apexMonth);
          setDisposableIncome(obj);
          setTransactionGroup(transactions);
        } else {
          setApexExpenseData([]);
          setApexMonth();
          setDisposableIncome({ income: 0, expenses: 0, affordability: 0 });
          setTransactionGroup([]);
        }
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      });
    }
  }, [selectedDateRang, endDate, selectedML]);
  useEffect(() => {
    const maxObject =
      consent &&
      consent.length &&
      consent.reduce((max, currentObject) => {
        if (
          currentObject.id > max.id &&
          currentObject?.dashboardAvailable &&
          currentObject?.consent?.data?.products.filter(
            (x) => x.code === 'affordability_full'
          ).length
        ) {
          return currentObject;
        }
        return max;
      }, consent[0]);
    if (maxObject && selectAccount !== 'all' && !selectAccount) {
      setSelectAccount(maxObject?.accountNumber);
      setSelectedAccountType(maxObject?.accountType);

      if (bankList.length) {
        const selectedIcon = bankList.find(
          (i) => maxObject?.bankName === i.bankName
        );
        setSelectedBankLogo(selectedIcon?.icon);
      }
    }
  }, [consent, bankList]);
  const handleExpand = () => {
    navigate('/expand-list', {
      state: {
        selectMonth: selectMonth
          ? filletElement.find(
              (item) => !selectedEndDate && item.value === selectMonth
            )?.value
          : filletElement[filletElement.length - 1].value,
        selectAccount: selectAccount,
        selectedEndDate: selectedEndDate ? selectedEndDate : endDate,
        selectedDateRang: selectedDateRang,
        accountType: selectedAccountType || selectedConsent?.accountType,
        selectedType:selectedType
      },
    });
  };
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />

      <PageWrapper>
        {loader ? (
          <Loader />
        ) : (
          <>
            <Stack
              mt={5}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              flexWrap="wrap"
            >
              <Typography variant="Body_semibold_28" sx={{ flexGrow: 1 }}>
                Category Insight
              </Typography>

              <Box sx={{ flexGrow: 1, mt: { sm: 0, xs: 5 } }}>
                <Grid container gap={2} justifyContent="flex-end">
                  {ML_SELECTION_AVAILABLE && (
                    <Grid item md={2.5} xs={12}>
                      <Typography variant="Body_medium_16" color="orange.main">
                        TestML Model
                      </Typography>
                      <Autocomplete
                        id="select-business-partner"
                        options={mlOptions}
                        getOptionLabel={(option) => option.title || ''}
                        PaperComponent={CustomPaper}
                        value={mlOptions?.find(
                          (item) => item.value === selectedML
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="filled"
                            id="filled-hidden-label-normal"
                            placeholder="Select..."
                            inputProps={{ 'aria-label': 'Without label' }}
                            hiddenLabel
                            fullWidth
                            {...params}
                          />
                        )}
                        onChange={(e, value) => {
                          setSelectedML(value?.value);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Account Number
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={accountsNumberList || []}
                      getOptionLabel={(option) => option.title || ''}
                      PaperComponent={CustomPaper}
                      disableClearable
                      value={accountsNumberList?.find(
                        (item) => item.value === selectAccount
                      )}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={props['data-option-index']}
                          component="li"
                          name={option.bankName}
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        >
                          <img
                            loading="lazy"
                            name={option.bankName}
                            width="35"
                            style={{ borderRadius: '50%' }}
                            src={option.icon}
                            alt=""
                          />
                          {option.title}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                {selectedBankLogo && (
                                  <Box
                                    component="img"
                                    src={selectedBankLogo}
                                    alt=""
                                    sx={{
                                      width: '35px',
                                      height: '35px',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectAccount(value?.value || null);
                        setSelectedBankLogo(value?.icon);
                      }}
                    />
                  </Grid>

                  <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Type
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={typeOptions}
                      getOptionLabel={(option) => option.title || ''}
                      PaperComponent={CustomPaper}
                      value={typeOptions.find(
                        (item) => item.value === selectedType
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectedType(value.value || '');
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={2.5} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Month
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={selectedDateRang}
                      getOptionLabel={(option) =>
                        moment(option).format("MMMM - YYYY")
                      }
                      PaperComponent={CustomPaper}
                      value={selectedDateRang.find(
                        (item) => item === selectedEndDate
                      )}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ "aria-label": "Without label" }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newDate) => {
                        setSelectedEndDate(
                          newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                        );
                      }}
                    />
                  </Grid> */}
                  <Grid item md={3} xs={12}>
                    <Typography variant="Body_medium_16" color="orange.main">
                      Filter Period
                    </Typography>
                    <Autocomplete
                      id="select-business-partner"
                      options={[...filletElement, ...selectedDateRang]}
                      getOptionLabel={(option) => {
                        if (
                          moment(option?.title).format('MMMM - YYYY') !==
                          'Invalid date'
                        ) {
                          return (
                            moment(option?.title).format('MMMM - YYYY') || ''
                          );
                        } else {
                          return option?.title || '';
                        }
                      }}
                      PaperComponent={CustomPaper}
                      value={
                        selectMonth
                          ? [...filletElement, ...selectedDateRang].find(
                              (item) => {
                                if (selectedEndDate) {
                                  return item.value === selectedEndDate;
                                } else {
                                  return item.value === selectMonth;
                                }
                              }
                            )
                          : filletElement[filletElement.length - 1]
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, newData) => {
                        setSelectMonth(newData?.value);
                        setSelectedEndDate(newData?.value);
                        // if (e.target.getInnerHTML()) {
                        //   let selected = filletElement.find(
                        //     (item) => item.title === e.target.getInnerHTML()
                        //   );
                        //   setSelectMonth(selected.value);
                        // }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>

            <MobileBox>
              <Typography variant="Body_light_14">
                View on a laptop/desktop computer to get the best experience
              </Typography>
            </MobileBox>
            <Grid container my={4} spacing={2}>
              <Grid item md={6} xs={12}>
                <MainCardStyled sx={{ height: { xs: 'auto', sm: '600px' } }}>
                  <Box>
                    <Typography variant="Body_medium_18">
                      Transaction Groups
                    </Typography>
                  </Box>

                  <Box my={2}>
                    <TextField
                      variant="filled"
                      id="filled-hidden-label-normal"
                      placeholder="Search for a bank..."
                      autoComplete="off"
                      hiddenLabel
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setNameSearch(e.currentTarget.value);
                      }}
                    />
                  </Box>

                  <TableWrapper>
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCellHead>Type</TableCellHead>
                          <TableCellHead>Category</TableCellHead>
                          <TableCellHead>Subcategory</TableCellHead>
                          <TableCellHead>Amount</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterValue?.map((row, index) => (
                          <TableRow hover key={index}>
                            <StyledTableCellData>
                              {row?.tran_type}
                            </StyledTableCellData>
                            <StyledTableCellData>
                              {row?.category_lvl1}
                            </StyledTableCellData>
                            <StyledTableCellData>
                              {row?.category_lvl2}
                            </StyledTableCellData>
                            <StyledTableCellData>
                              {row?.[selectedType] ? (
                                <>
                                  ₦{' '}
                                  {(row?.[selectedType] || 0).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </>
                              ) : null}
                            </StyledTableCellData>
                          </TableRow>
                        ))}
                        <TableRow hover>
                          <StyledTableCellDataItem>
                            Total
                          </StyledTableCellDataItem>
                          <StyledTableCellDataItem></StyledTableCellDataItem>
                          <StyledTableCellDataItem></StyledTableCellDataItem>
                          <StyledTableCellDataItem>
                            ₦{' '}
                            {(grantTotal || 0).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </StyledTableCellDataItem>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableWrapper>

                  <Box sx={{ position: 'relative' }}>
                    <ExpandListBtn onClick={handleExpand}>
                      <OpenWithIcon
                        sx={{ fill: (theme) => theme.palette.common.white }}
                      />
                      <Typography> Expand list</Typography>
                    </ExpandListBtn>
                  </Box>
                </MainCardStyled>
              </Grid>

              <Grid item md={6} xs={12}>
                <MainCardStyled sx={{ height: { xs: 'auto', sm: '600px' } }}>
                  <Typography variant="Body_medium_18">
                    Disposable Income
                  </Typography>

                  <DonutChart
                    value={disposableIncome?.affordability}
                    disposableIncome={disposableIncome}
                    // incomePer={donetsIncomes}
                    // expensePer={donetsExpenses}
                    // totalAmount={donetsAffordability}
                  />
                </MainCardStyled>
              </Grid>

              <Grid item xs={12}>
                <MainCardStyled>
                  <Typography variant="Body_medium_18">
                    Monthly Transactions
                  </Typography>
                  <ApexColumnChart
                    apexIncomeData={apexIncomeData}
                    apexExpenseData={apexExpenseData}
                    apexMonth={apexMonth}
                  />
                </MainCardStyled>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                opacity: '0.5',
              }}
            >
              Data Fetch from{' '}
              {moment(
                moment(dateToValueData?.title).format('MMMM - YYYY') !==
                  'Invalid date'
                  ? dateToValueData?.value
                  : endDate
              )
                .subtract(
                  moment(dateToValueData?.title).format('MMMM - YYYY') !==
                    'Invalid date'
                    ? 1
                    : selectMonth ||
                        filletElement[filletElement.length - 1].value,
                  'month'
                )
                .format('DD/MM/YYYY')}{' '}
              to{' '}
              {moment(
                moment(dateToValueData?.title).format('MMMM - YYYY') !==
                  'Invalid date'
                  ? dateToValueData?.value
                  : endDate
              ).format('DD/MM/YYYY')}
            </Box>
          </>
        )}
      </PageWrapper>
    </>
  );
};
function calculateDateRang(allDateRange, setSelectedDateRang) {
  var result = [];
  allDateRange?.forEach((v) => {
    var startDate = moment(v.startDate);
    var endDate = moment(v.endDate);
    if (endDate.isBefore(startDate)) {
      return;
    }
    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      let diff = moment(endDate).diff(moment(lastDate), 'days');
      if (diff < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }
      result.push(lastDate);
      startDate.add(1, 'month');
    }
    result
      .sort(
        (a, b) =>
          new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
      )
      .reverse();
    function filterUniqueDates(data) {
      const lookup = new Set();
      return data.filter((date) => {
        const serialised = new Date(date).getTime();

        if (lookup.has(serialised)) {
          return false;
        } else {
          lookup.add(serialised);
          return true;
        }
      });
    }
    let uniqueDates = filterUniqueDates(result);
    uniqueDates =
      uniqueDates.length &&
      uniqueDates.map((i) => {
        return {
          title: i,
          value: i,
        };
      });
    setSelectedDateRang(uniqueDates);
  });
}
export default CategoryInsights;
