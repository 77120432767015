import AppHttpClient from '../components/common/api'


export class BusinessPartnerService {

    constructor() {

    }


    GetBusinessPartners() {
        return AppHttpClient("orchestrator/businessPartners", "get").then((res) => {
            if (res?.status === 200) {
                return (res?.data.length ? res?.data : res?.data?.data);
            } else {
                throw res.message
            }
        });
    }
}