import { Button } from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/login");
  };
  return (
    <>
    {!JSON.parse(localStorage.getItem("userData"))?.isGuestUser && (
      <Button
        variant="contained"
        startIcon={<LogoutIcon />}
        className="btn-logout"
        onClick={handleLogout}
      >
        Logout
      </Button>
    )}
    </>
  );
};

export default Logout;
