import PropTypes from "prop-types";
import { forwardRef, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import BackIcon from "../../assets/BackIcon.svg";
import {
  BoxDesc,
  BoxText,
  BoxTextWrapper,
  ButtonWrapper,
  CurrentDataTitle,
  PermissionDesc,
  PermissionsBox,
  PermissionsDetailsBox,
  PermissionTitle,
} from "./PlansStyle";
import { Link as RouterLink } from "react-router-dom";
import StatementIcon from "../../assets/Statement.svg";
import BalanceIcon from "../../assets/Balance.svg";

import { FillButton, OutlineButton } from "../../components/common/style";
import MainCard from "../../components/common/MainCard";
import {
  PermissionCardMainText,
  PermissionCardSubText,
} from "../permission/PermissionStyle";
// ==============================|| PRODUCT ADD DIALOG ||============================== //

// animation
const Transition = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

function CircularProgressWithLabel1({ value, ...others }) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress variant="determinate" value={value} {...others} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="Body_semibold_32" component="div" color="">
          76
        </Typography>
        <Typography variant="Body_light_12">days left</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel1.propTypes = {
  value: PropTypes.number,
};

//
const ManagePlans = ({ open, handleCloseDialog }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      sx={{
        "&>div:nth-of-type(3)": {
          justifyContent: "flex-end",
          "&>div": {
            m: 0,
            borderRadius: "0px",
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            backgroundColor: theme.palette.primary.light,
            padding: 0,

            [theme.breakpoints.up("lg")]: {
              width: "50%",
              padding: theme.spacing(2, 2, 2, 2),
            },
          },
        },
      }}
    >
      {open && (
        <>
          <DialogContent mb={2}>
            <PermissionsBox>
              <Box component={RouterLink} to="/home">
                <Box component="img" src={BackIcon} alt="back" />
              </Box>
              <Typography
                variant="Body_medium_16"
                color="grey.lighter"
                ml={1.25}
              >
                Back to All permissions
              </Typography>
            </PermissionsBox>

            {/*  */}
            <PermissionsDetailsBox>
              <Stack direction="column" gap={1.4}>
                <PermissionTitle>Permission Details</PermissionTitle>
                <PermissionDesc>
                  Currently viewing permission details for Sterling Bank
                </PermissionDesc>
              </Stack>

              <>
                <Grid
                  container
                  spacing={2}
                  mt={3}
                  mb={5}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <CircularProgressWithLabel1
                      value={53}
                      sx={{ color: "#40EA8F" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle"
                      color="grey.main"
                      alignItems="center"
                    >
                      Status{" "}
                      <span
                        style={{
                          ...theme.typography.Body_semibold_16,
                          color: "#40EA8F",
                        }}
                      >
                        Active
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </>

              <Box>
                <CurrentDataTitle>
                  The data we currently have access to
                </CurrentDataTitle>

                <FormControl>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "start" }}>
                      <Box component="img" src={StatementIcon} alt="account" />
                      <BoxTextWrapper>
                        <BoxText>Your accounts</BoxText>
                        <BoxDesc>
                          For you to use this service E-DocOnline needs to
                          access information from your accounts at your bank.
                        </BoxDesc>
                      </BoxTextWrapper>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "start" }}>
                      <Box component="img" src={BalanceIcon} alt="account" />
                      <BoxTextWrapper>
                        <BoxText>Your balance</BoxText>
                        <BoxDesc>
                          For you to use this service E-DocOnline needs to
                          access information from your accounts at your bank.
                        </BoxDesc>
                      </BoxTextWrapper>
                    </Box>
                  </Box>
                </FormControl>
              </Box>

              <Grid container spacing={4} my={2}>
                <Grid item xs={12} md={6}>
                  <MainCard sx={{ backgroundColor: theme.palette.primary.bg }}>
                    <Stack spacing={1.2}>
                      <PermissionCardMainText>
                        You started sharing your data on{" "}
                      </PermissionCardMainText>
                      <PermissionCardSubText>20 May 2022</PermissionCardSubText>{" "}
                    </Stack>
                  </MainCard>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MainCard sx={{ backgroundColor: theme.palette.primary.bg }}>
                    <Stack spacing={1.2}>
                      <PermissionCardMainText>
                        We will access your data until
                      </PermissionCardMainText>
                      <PermissionCardSubText>17 Aug 2022</PermissionCardSubText>{" "}
                    </Stack>
                  </MainCard>
                </Grid>
              </Grid>
            </PermissionsDetailsBox>

            {/*  */}
            <ButtonWrapper>
              <Box sx={{ width: "inherit" }}>
                <AnimateButton>
                  <Button
                    sx={{ width: "-webkit-fill-available" }}
                    size="large"
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseDialog}
                  >
                    Back
                  </Button>
                </AnimateButton>
              </Box>

              <Box sx={{ width: "inherit" }}>
                <AnimateButton>
                  <Button
                    sx={{ width: "-webkit-fill-available" }}
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                    component={RouterLink}
                    to="/cancel-plan"
                  >
                    Cancel Permission
                  </Button>
                </AnimateButton>
              </Box>
            </ButtonWrapper>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

ManagePlans.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default ManagePlans;
