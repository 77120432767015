import React from "react";
import SuccessModal from "../../../components/common/SuccessModal";

const PasswordResetSuccess = () => {
  return (
    <>
      <SuccessModal
        successMsg="Password reset"
        success="successful"
        subTitle="You have successfully reset your password."
        subTitle1=" proceed to Log In"
        btnName="Log in"
        to="/login"
      />
    </>
  );
};

export default PasswordResetSuccess;
