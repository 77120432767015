import { Box, Typography, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
} from "../Auth/AuthStyle";
import { Link as RouterLink } from "react-router-dom";

import SuccessLogo from "../../assets/Success.svg";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { FillButton, OutlineButton } from "../../components/common/style";

const ConsentSuccess = (props) => {
  // useEffect(()=>{
  //   localStorage.removeItem('deepLinkId')
  // },[])
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="box"
            style={{
              maxWidth: 670,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={SuccessLogo}
                height={85}
                width={85}
                mb={5}
              />

              <Typography variant="Body_semibold_32" mb={3} textAlign="center">
                Thank You
              </Typography>

              <Box textAlign="center" mb={7}>
                <Typography
                  variant="Body_light_16"
                  mb={1}
                  color="grey.darker"
                  textAlign="center"
                >
                  Copy of your application will be sent to your email id
                </Typography>
                <br />
                {/* <Typography
                  variant="Body_light_16"
                  mb={5}
                  color="grey.main"
                  textAlign="center"
                  // sx={{ display: "flex" }}
                >
                  We will access your information from your accounts until
                  &nbsp;
                  <span style={{ fontWeight: "600" }}>20 November 2022</span>
                </Typography>{" "} */}
                <br />
              </Box>
            </Box>

            {/* <Grid container gap={2} alignItems="center" justifyContent="center">
              <AnimateButton>
                <FillButton
                  size="large"
                  component={RouterLink}
                  to="/dashboard"
                  type="submit"
                  variant="contained"
                  sx={{ width: "100%" }}
                >
                  View dashboard
                </FillButton>
              </AnimateButton>

              <AnimateButton>
                <OutlineButton
                  size="large"
                  component={RouterLink}
                  to="/view-plans"
                  type="submit"
                  variant="contained"
                  sx={{ color: "orange.main" }}
                >
                  View plan
                </OutlineButton>
              </AnimateButton>
            </Grid> */}
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};

export default ConsentSuccess;
