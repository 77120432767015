import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import { Box, Divider, FormControlLabel, TextField } from '@mui/material';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 26,
  height: 26,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

  backgroundColor: theme.palette.common.white,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#f4f4f4',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.orange.main,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 26,
    height: 26,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.orange.main,
  },
}));

export function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const RadioFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  marginLeft: theme.spacing(2.5),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.primary.lighter,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

export const SelectableTextField = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(0.3),
  backgroundColor: theme.palette.primary.light,
  minHeight: 60,
  borderRadius: '16px 0 0 16px',
}));

export const SearchTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  minHeight: 60,
  borderRadius: '0 16px 16px 0',
}));

export const SearchBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(6),
}));

export const BankWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingRight: '10px',
  height: '520px',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
}));
