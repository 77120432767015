import {
  Box,
  FormControlLabel,
  Link,
  Radio,
  Slider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const RootStyle = styled(Box)({
  height: "100%",
  position: "relative",
  margin: 40,
});

export const FromWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  width: "100%",
  // height: "85%",
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    minHeight: "100vh",
  },
}));

export const FormStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2),
  margin: theme.spacing(8, 1, 5, 1),
  border: "none",
  width: "400px",

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0),
    padding: theme.spacing(3, 3.75),
    border: `2px solid ${theme.palette.primary.light}`,
    width: "550px",
  },
  "@media (max-width:400px)": {
    width: "300px",
  },
  "@media (max-width:300px)": {
    width: "280px",
  },
  "@media (max-width:280px)": {
    width: "auto",
  },
}));

export const FormInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px 0",
  marginBottom: theme.spacing(4),
}));

export const FormInputWrapper = styled(FormInfoBox)(({ theme }) => ({
  gap: theme.spacing(2, 0),
}));

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.orange.main,
  height: 11,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: theme.palette.orange.main,
    boxShadow: "none",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    top: 62,
    backgroundColor: "unset",
    color: theme.palette.common.white,
    ...theme.typography.Body_medium_14,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.primary.light,
  },
}));
