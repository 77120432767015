import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MainCard from "../../components/common/MainCard";

export const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 5, 2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 10, 5, 10),
  },
}));

export const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  padding: theme.spacing(1),

  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.primary.color1,
  },
}));

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: "auto",
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    maxHeight: "350px",
  },
}));

export const ListForBusiness = styled('li')(({ theme }) => ({
  ...theme.typography.body1,
  marginBottom:"1rem"
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  borderTop: `1px solid ${theme.palette.primary.bg}`,
  // border: "none",
  backgroundColor: "transparent",
  color: theme.palette.grey.light,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const StyledTableCellData = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  ...theme.typography.Body_light_14,
}));
