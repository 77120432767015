import { styled } from "@mui/material/styles";
import { Box, TableCell, TableContainer, Typography } from "@mui/material";
import MainCard from "../../components/common/MainCard";
//

export const OverviewWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),

  [theme.breakpoints.up("lg")]: {
    margin: theme.spacing(5),
  },
}));

export const PageBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 5, 0, 5),
  },
}));

export const MainCardStyled = styled(MainCard)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.primary.color1,
  },
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  // border: "none",
  backgroundColor: theme.palette.primary.color1,
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  color: theme.palette.grey.light,
  ...theme.typography.Body_medium_14,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const TableCellData = styled(TableCell)(({ theme }) => ({
  // border: "none",
  borderBottom: `1px solid ${theme.palette.primary.bg}`,
  color: theme.palette.common.white,
  ...theme.typography.Body_medium_14,
}));
