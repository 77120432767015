import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicStatement from "../../assets/Basic-statement.svg";
import { LoadingButton } from "@mui/lab";
import GetAxiosInstance from "../../components/common/api";
import RealtimeBalance from "../../assets/Realtime-balance.svg";
import Percentage from "../../assets/Percentage.svg";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../components/common/common";
import { toast } from "react-toastify";
import AnimateButton from "../../components/common/formStyle/AnimateButton";


const ProductDetails = ({
  isOpen,
  setIsOpen,
  newConsent,
  accountDetails,
  onClose,
}) => {
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const [priceData, setPriceData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (isOpen) {
      GetAxiosInstance('orchestrator/consent-price', 'post', newConsent).then(
        (res) => {
          if (res?.data?.codes === 200) {
            setPriceData(res?.data.data.consentPrice);
          }
        }
      );
    }
  }, [isOpen]);
  const handleAccept = () => {
    let userData = getUserData();
    setLoader(true);
    GetAxiosInstance(
      'orchestrator/initialize-consent',
      'post',
      newConsent
    ).then((res) => {
      if (res?.data?.codes === 200) {
        let postObj = {
          journey: 'CONSENT',
          eventName: 'CONSENT.INITIATED',
          status: 'SUCCESS',
          uniqueIdentifier: res?.data?.data?.consentId,
          subIdentifier: accountDetails?.bankName,
          data: {
            req: newConsent,
            res: res?.data?.data,
          },
        };
        if (localStorage.getItem('deepLinkId')) {
          postObj['source'] = 'BUSINESS_PARTNER';
        } else {
          postObj['source'] = 'CONNECT';
        }

        GetAxiosInstance(`log/eventLog`, 'post', postObj);
        if (res?.data?.data?.verificationType === 1) {
          setLoader(false);
          navigate('/pending-approval', {
            state: { ...res.data?.data, bankName: accountDetails?.bankName },
          });
        } else if (
          res?.data?.data?.verificationType === 2 ||
          res?.data?.data?.verificationType === 3
        ) {
          setLoader(false);

          navigate('/verify-otp', {
            state: { ...res.data?.data, bankName: accountDetails?.bankName },
          });
        } else if (
          res?.data?.data?.verificationType === 0 ||
          res?.data?.data?.verificationType === 'none'
        ) {
          // setLoader(false);
          const payload = {
            consentId: res?.data?.data?.consentId,
          };
          GetAxiosInstance(
            'orchestrator/get-transactions',
            'post',
            payload,
            userData.accessToken
          ).then((res) => {
            if (res?.data?.codes === 200) {
              setLoader(false);
              navigate('/dashboard', {
                state: {
                  ...res.data?.data,
                  consentId: payload?.consentId,
                  bankName:
                    accountDetails?.bankName === 'wema'
                      ? process.env.REACT_APP_WEMA_KEY
                      : accountDetails?.bankName,
                },
              });
            } else {
              setLoader(false);
              toast.error(res?.response?.data?.message || res.message);
            }
          });
        }
      } else {
         let postObj = {
           journey: 'CONSENT',
           eventName: 'CONSENT.INITIATED',
           status: 'ERROR',
           uniqueIdentifier: res?.data?.data?.consentId,
           subIdentifier: accountDetails?.bankName,
           data: {
             req: newConsent,
             res: res.response.data.message,
           },
         };
         if (localStorage.getItem('deepLinkId')) {
           postObj['source'] = 'BUSINESS_PARTNER';
         } else {
           postObj['source'] = 'CONNECT';
         }
         GetAxiosInstance(`log/eventLog`, 'post', postObj);
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '750px',
            borderRadius: '12px',
            bgcolor: theme.palette.primary.light,
            p: 4,
          },
        }}
      >
        <Box>
          <Typography variant="Body_semibold_24">Product Details</Typography>

          <Box
            my={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              border: `2px solid ${theme.palette.primary.bg}`,
              borderRadius: '12px',
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(3),
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(3.5),
              }}
            >
              {priceData?.products.length ? (
                <>
                  {priceData?.products.map((item, index) => (
                    <Box component={'span'} key={index}>
                      {item?.group === 'statement' && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Box
                              component="img"
                              src={BasicStatement}
                              alt="BasicStatement"
                            />
                            <Typography variant="Body_medium_18">
                              {item?.name}
                            </Typography>
                          </Stack>

                          <Typography
                            variant="Body_light_18"
                            sx={{
                              color: theme.palette.grey.light,
                            }}
                          >
                            ₦ {item?.price}
                          </Typography>
                        </Stack>
                      )}
                      {item?.group === 'balance' && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Box
                              component="img"
                              src={RealtimeBalance}
                              alt="RealtimeBalance"
                            />
                            <Typography variant="Body_medium_18">
                              {item?.name}
                            </Typography>
                          </Stack>

                          <Typography
                            variant="Body_light_18"
                            sx={{
                              color: theme.palette.grey.light,
                            }}
                          >
                            ₦ {item?.price}
                          </Typography>
                        </Stack>
                      )}
                      {item?.group === 'affordability' && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Box
                              component="img"
                              src={Percentage}
                              alt="Percentage"
                            />
                            <Typography variant="Body_medium_18">
                              {item?.name}
                            </Typography>
                          </Stack>

                          <Typography
                            variant="Body_light_18"
                            sx={{
                              color: theme.palette.grey.light,
                            }}
                          >
                            ₦ {item?.price}
                          </Typography>
                        </Stack>
                      )}
                      {item?.group === 'kyc' && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Box
                              component="img"
                              src={Percentage}
                              alt="Percentage"
                            />
                            <Typography variant="Body_medium_18">
                              {item?.name}
                            </Typography>
                          </Stack>

                          <Typography
                            variant="Body_light_18"
                            sx={{
                              color: theme.palette.grey.light,
                            }}
                          >
                            ₦ {item?.price}
                          </Typography>
                        </Stack>
                      )}
                      {item?.group === 'pof' && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Box
                              component="img"
                              src={RealtimeBalance}
                              alt="RealtimeBalance"
                            />
                            <Typography variant="Body_medium_18">
                              {item?.name}
                            </Typography>
                          </Stack>

                          <Typography
                            variant="Body_light_18"
                            sx={{
                              color: theme.palette.grey.light,
                            }}
                          >
                            ₦ {item?.price}
                          </Typography>
                        </Stack>
                      )}
                    </Box>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box
              sx={{
                background: theme.palette.primary.bg,
                padding: theme.spacing(3),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="Body_medium_20">Total Amount</Typography>
              <Typography variant="Body_medium_20">
                ₦ {priceData?.totalPrice}
              </Typography>
            </Box>
          </Box>

          <Stack
            mt={6}
            direction="row"
            alignItems="center"
            spacing={4}
            justifyContent="space-between"
          >
            <Button
              sx={{ width: '-webkit-fill-available' }}
              size="large"
              // component={RouterLink}
              to="/view-plans"
              type="submit"
              variant="outlined"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            {/* <AnimateButton> */}
            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              sx={{ width: '-webkit-fill-available' }}
              onClick={handleAccept}
              loadingIndicator={
                <CircularProgress
                  style={{
                    padding: '60px',
                    color: theme.palette.orange.main,
                  }}
                />
              }
              loading={loader}
            >
              Allow
            </LoadingButton>
            {/* </AnimateButton> */}
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default ProductDetails;
