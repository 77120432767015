// ----------------------------------------------------------------------

const GREY = {
  lighter: "#DEDEDE",
  light: "#A3A3A3",
  main: "#A5A6B6",
  dark: "#6E6F87",
  darker: "#FCFCFC",
  color1: "#636363",
  500: "#9e9e9e",
  99: "#ffffff99",
};

const DARK = {
  light: "#767E8E",
  main: "#1B1D21",
  dark: "#191B1F",
  darker: "#17191C",
};

const PRIMARY = {
  lighter: "#005279",
  light: "#002638",
  main: "#001823",
  dark: "#002F80",
  darker: "#001333",
  bg: "#002E45",
  caption: "#4C75F5",
  color1: "#002030",
};
const SECONDARY = {
  lighter: "#DAD6F3",
  light: "#A39AE3",
  main: "#6C5DD2",
  dark: "#34268B",
  darker: "#0F0B28",
  chart: "#004466",
};

const SUCCESS = {
  lighter: "#DAF1E3",
  light: "#91D4AA",
  main: "#40EA8F",
  dark: "#2B6E44",
  darker: "#0F2517",
};

const DANGER = {
  lighter: "#F9CEC6",
  light: "#F28D7C",
  main: "#ED3E3E",
  dark: "#EB4B33",
  darker: "#CC2D14",
};

const ORANGE = {
  main: "#FC5719",
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  danger: { ...DANGER },
  success: { ...SUCCESS },
  dark: { ...DARK },
  orange: { ...ORANGE },
  grey: { ...GREY },
  text: { primary: "#fff" },
  // background: { paper: "#fff", default: GREY[100], neutral: GREY[200] },
};

export default palette;
