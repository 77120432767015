import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  CancelButton,
  CommonWrapper,
  PageSubTitle,
  PageTitle,
  PageWrapper,
  ReadPermissionTitle,
  SubmitButton,
  TitleWrapper,
} from "../permission/PermissionStyle";
import {
  BankWrapper,
  BpRadio,
  CountrySelectedTextField,
  RadioFormControlLabel,
  SearchBoxWrapper,
  SearchTextField,
  SelectableTextField,
  StyledDivider,
} from "./BankSelectionStyle";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { FillButton, OutlineButton } from "../../components/common/style";
import Bank1 from "../../assets/Bank1.png";
import Bank2 from "../../assets/Bank2.png";
import Bank3 from "../../assets/Bank3.png";
import Bank4 from "../../assets/Bank4.png";
import Bank5 from "../../assets/Bank5.png";
import keystone from "../../assets/KeyStone.png";

const BankSeleciton = () => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <PageWrapper>
        <TitleWrapper>
          <PageTitle>Select your Bank</PageTitle>

          <PageSubTitle>
            To select your bank feed we need to know which bank to connect to.
          </PageSubTitle>

          <SearchBoxWrapper>
            <SelectableTextField
              variant="standard"
              value="Nigeria"
              sx={{}}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment>
                    <ArrowDropDownIcon
                      sx={{ color: theme.palette.common.white }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <SearchTextField
              variant="standard"
              autoFill="false"
              fullWidth
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon sx={{ color: theme.palette.common.white }} />
                  </InputAdornment>
                ),
              }}
              placeholder="Search for a bank..."
              // onChange={handleSearch}
              // value={search}
            />
          </SearchBoxWrapper>
        </TitleWrapper>

        <CommonWrapper>
          <ReadPermissionTitle>
            Please select a bank from the List below
          </ReadPermissionTitle>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
            >
              <BankWrapper>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    src={keystone}
                    alt="account"
                    sx={{ width: "50px", height: "50px" }}
                  />
                  <RadioFormControlLabel
                    value="NIBSS Plc"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Keystone Bank"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank2} alt="account" />
                  <RadioFormControlLabel
                    value="Sterling Bank"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Sterling Bank"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank3} alt="account" />
                  <RadioFormControlLabel
                    value="Access Bank"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Access Bank"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank4} alt="account" />
                  <RadioFormControlLabel
                    value="Guaranty Trust Bank"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Guaranty Trust Bank"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank5} alt="account" />
                  <RadioFormControlLabel
                    value="Stanbic IBTC Bank"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Stanbic IBTC Bank"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank1} alt="account" />
                  <RadioFormControlLabel
                    value="NIBSS Plc1"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="NIBSS Plc"
                  />
                </Box>
                <StyledDivider />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="img" src={Bank2} alt="account" />
                  <RadioFormControlLabel
                    value="Sterling Bank1"
                    control={<BpRadio />}
                    labelPlacement="start"
                    label="Sterling Bank"
                  />
                </Box>
                <StyledDivider />
              </BankWrapper>
            </RadioGroup>
          </FormControl>

          <Typography
            mt={6}
            textAlign="center"
            variant="Body_light_16"
            color="grey.darker"
          >
            Are you sure you have selected the right bank, and wish to continue?
          </Typography>

          <Grid container spacing={2} alignItems="center" my={7}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={4}
              >
                <Grid item>
                  <AnimateButton>
                    <OutlineButton
                      size="large"
                      type="submit"
                      variant="contained"
                      component={RouterLink}
                      to="/account-information"
                    >
                      Go Back
                    </OutlineButton>
                  </AnimateButton>
                </Grid>
                <Grid item>
                  <AnimateButton>
                    <FillButton
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleClickOpenDialog}
                    >
                      Continue
                    </FillButton>
                  </AnimateButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CommonWrapper>
      </PageWrapper>

      {/* {open && (
        <RedirectionModal open={open} handleCloseDialog={handleCloseDialog} />
      )} */}
    </>
  );
};

export default BankSeleciton;
