import { Button } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GetAxiosInstance from "./api";

const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.email) {
      try {
        const response = await GetAxiosInstance('auth/changePasswordOtp', 'post', { email: userData.email });
        if (response?.data?.codes === 200) {
          const obj = {
            email: userData.email,
            flow: 'changePasswordOtp',
          };
          navigate('/confirm', { state: obj });
          toast.success('OTP sent successfully');
        } else {
          toast.error(response?.data?.message ?? 'An unexpected error occurred');
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? 'Network error or unexpected issue');
      }
    } else {
      toast.error("User data not found or email is missing.");
    }
  };
  return (
    <>
    {!JSON.parse(localStorage.getItem("userData"))?.isGuestUser && (
      <Button
        variant="contained"
        
        className="btn-logout"
        onClick={handleLogout}
      >
       Change Password
      </Button>
    )}
    </>
  );
};

export default Logout;
