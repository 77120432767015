import {
  CardContent,
  Box,
  Button,
  Card,
  Typography,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const DashboardSidebarWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 2),
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(18, 2, 0, 2),
  },
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  minHeight: 165,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const AccountLinkWrapper = styled(Box)(({ theme }) => ({
  minHeight: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    minHeight: 165,
  },
}));

export const AddAccountWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: theme.spacing(5),
}));

export const BankNameWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "auto",
  alignItems: " center  ",
  flexWrap: "wrap",
  backgroundColor: theme.palette.primary.color1,
  padding: theme.spacing(2),
  borderRadius: 12,

  [theme.breakpoints.up("md")]: {
    width: 365,
  },
}));

export const BankInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: theme.spacing(3),
  margin: theme.spacing(3, 2),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(5, 5),
  },
}));

export const ListItemsWrapper = styled(Box)(({ theme }) => ({
  // margin: theme.spacing(0, 3),
  display: "flex",
  width: "100%",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

export const ListItem = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 8),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
}));
