import PropTypes from "prop-types";
import React from "react";

// third-party
import { motion } from "framer-motion";

// ==============================|| ANIMATION BUTTON ||============================== //

const AnimateButton = React.forwardRef(({ children, type, scale }, ref) => {
  switch (type) {
    case "scale":
    default:
      if (typeof scale === "number") {
        scale = {
          hover: scale,
          tap: scale,
        };
      }
      return (
        <motion.div
          ref={ref}
          whileHover={{ scale: scale?.hover }}
          whileTap={{ scale: scale?.tap }}
        >
          {children}
        </motion.div>
      );
  }
});

AnimateButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["scale"]),
  scale: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

AnimateButton.defaultProps = {
  scale: {
    hover: 1,
    tap: 0.9,
  },
};

export default AnimateButton;
