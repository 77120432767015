import {
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableRow,
  Typography,
  useTheme,
  TableBody,
  TableHead,
} from '@mui/material';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api.js";
import { getUserData } from "../../components/common/common.js";
import CustomizedCheckbox from "../../components/common/formStyle/CheckBoxStyled.js";
import StyledCircularProgress from "../../components/common/StyledCircularProgress.js";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav.js";
import { LabelDescription } from "../permission/PermissionStyle.js";
import {
  MainCardStyled,
  PageWrapper,
  StyledTableCellDataItem,
  StyledTableCellData,
  TableCellHead,
  TableWrapper,
  ListForBusiness,
} from "./IncomeAndJobVerificationStyle.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader.js";

const ProofOfFunds = () => {
  const navigator = useNavigate();
  let userData = getUserData();
  const [dashboardData, setDashboardData] = useState([]);
  const [transection, setTransection] = useState([]);
  const [consent, setConsent] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectAccount, setSelectAccount] = useState([]);
  const [consents, setConsents] = useState();
  const [loader, setLoader] = useState();
  const [accountsNumberList, setAccountsNumberList] = useState();
  const [companyDetailData, setCompanyDetailData] = useState();
  const [metrics, setMetrics] = useState();
  const [transactionInfo, setTransactionInfo] = useState();
  const [statementAccessData, setStatementAccessData] = useState();
  const [lastTransactionData, setLastTransactionData] = useState();
  const [filterTransactionInfo, setFilterTransactionInfo] = useState([]);
  const [isCheckVerification, setIsCheckVerification] = useState({
    bankVerification: true,
    customerVerification: true,
    addressVerification: true,
  });

  const theme = useTheme();
  useEffect(() => {
    let endDate = moment().format("YYYY-MM-DD");
    let selectAccountNumber = selectAccount
      ? selectAccount
      : accountsNumberList[0]?.value;
    let selectedConsent = consents?.find((v) => {
      return v?.accountNumber === selectAccountNumber;
    });
    setSelectedDate(selectedConsent);
    let dashboardPayload = {
      userId: userData?.id,
      date_to: endDate,
      n_months: 12,
      account_id: (accountsNumberList && accountsNumberList[0]?.value) || "0",
    };

    GetAxiosInstance("orchestrator/get-kyc", "post", dashboardPayload).then(
      (res) => {
        if (res?.data?.codes === 200) {
          setDashboardData(res?.data?.data?.monthly);
          setCompanyDetailData(res?.data?.data?.companyDetails);
          setTransactionInfo(res?.data?.data?.kycDetails);
          if (res?.data.data.kycDetails.data.length) {
            setMetrics(res?.data?.data?.kycDetails?.monthly);
            setTransection(res?.data.data.kycDetails.data);
          }
        } else {
          setTransection([]);
          setDashboardData([]);
          setLoader(false);
          toast.error(res?.response?.data?.message || res.message);
        }
      }
    );
  }, [selectAccount, accountsNumberList, consents]);
  useEffect(() => {
    let obj = {
      isSalary: false,
      isDividends: false,
      businessIncome: false,
      investments: false,
      others: false,
    };
    transactionInfo?.data?.length &&
      transactionInfo?.data.forEach((item) => {
        if (item?.category?.match("Business Income")) {
          obj = { ...obj, businessIncome: true };
        } else if (item?.category?.match("Salary")) {
          obj = { ...obj, isSalary: true };
        } else if (item?.category?.match("Dividend")) {
          obj = { ...obj, isDividends: true };
        } else {
          obj = { ...obj, others: true };
        }
      });
    setFilterTransactionInfo(transactionInfo?.data);
  }, [transactionInfo]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let allProducts = [];
    setLoader(true);
    let endDate = moment().format("YYYY-MM-DD");
    let hasAffordabilityAccess = false;
    await GetAxiosInstance(`orchestrator/get-consent`, "get").then((res) => {
      if (res.data?.codes === 200) {
        if (res.data?.data?.consents.length) {
          setConsents(res?.data?.data?.consents);
          res.data?.data?.consents.map((v) => {
            if (
              v.consent.data.products.filter((item) => item.code === "kyc")
                .length
            ) {
              if (v?.accountNumber) {
                [].push({
                  title: `XXXXXX${v.accountNumber.slice(-4)}`,
                  value: v.accountNumber,
                });
              }
            } else {
              hasAffordabilityAccess = true;
            }
          });
          if (![].length) {
            navigator("/poof-of-funds");
          }

          let selectedProduct = res?.data?.data?.consent?.find(
            (item) => item.accountNumber === selectAccount
          );
          if (selectedProduct && selectAccount?.length) {
            endDate = moment(selectedProduct?.consent?.data?.dateTo).format(
              "YYYY-MM-DD"
            );
          } else {
            let dateRangArray = res.data.data?.consents.map((v) => {
              return {
                startDate: v.consent?.data?.dateFrom,
                endDate: v.consent?.data?.dateTo,
              };
            });
            let allEndDate = res.data?.data?.consents?.map(
              (v) => v.consent?.data?.dateTo
            );
            let maxEndDate =
              allEndDate.length &&
              allEndDate.reduce(function (a, b) {
                return a > b ? a : b;
              });

            endDate = moment(maxEndDate).format("YYYY-MM-DD");
          }
          setAccountsNumberList([]);
          res.data?.data?.consents?.forEach((v) => {
            if (
              v?.consent?.data?.products.length &&
              v?.consent?.data?.products &&
              v?.consent?.data?.accountNumber ===
              (selectAccount || [][0]?.value)
            ) {
              v?.consent?.data?.products.forEach((i) => {
                if (i.group === "statement") {
                  i.months.forEach((j) => {
                    let obj = {
                      accountNumber: v.accountNumber,
                      statementMonth: j,
                    };

                    allProducts.push(obj);
                  });
                }
              });
            }
          });
          setStatementAccessData(allProducts);
        }

        setConsent(res.data?.data?.consents);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || "Something went wrong!"
        );
      }
    });
  }, [selectAccount]);
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} consent={consent} />
      {!loader ? (
        <PageWrapper>
          <>
            <Grid container my={4} spacing={2}>
              <Grid item md={6} xs={12}>
                <MainCardStyled
                  sx={{
                    height: { xs: 'auto', md: '480px', sm: '600px' },
                  }}
                >
                  <Box>
                    <Typography variant="Body_medium_16">Rating</Typography>
                  </Box>

                  <Grid container my={3} spacing={2}>
                    <Grid item md={4} xs={6}>
                      <Stack direction="column" alignItems="center" gap={2}>
                        <StyledCircularProgress
                          progressValue={
                            transactionInfo?.last_trasaction_date !== 'nan'
                              ? moment(
                                  transactionInfo?.last_trasaction_date
                                ).format('DD-MMM-YYYY')
                              : moment(lastTransactionData).format(
                                  'DD-MMM-YYYY'
                                )
                          }
                          indicatorColor={theme.palette.orange.main}
                          fontSize="9px"
                        />
                        <Typography variant="Body_medium_14" textAlign="center">
                          Last Transaction Date
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <Stack direction="column" alignItems="center" gap={2}>
                        <StyledCircularProgress
                          progressValue={transactionInfo?.n_income_source}
                          indicatorColor={theme.palette.success.main}
                        />
                        <Typography variant="Body_medium_14" textAlign="center">
                          Source of Income
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <Stack direction="column" alignItems="center" gap={2}>
                        <StyledCircularProgress
                          progressValue={
                            transactionInfo?.transactions_risk_level
                          }
                          indicatorColor={`${
                            transactionInfo?.transactions_risk_level === 'High'
                              ? '#FF0000'
                              : '#40EA8F'
                          }`}
                        />
                        <Typography variant="Body_medium_14" textAlign="center">
                          Transaction Risk Level
                          <br />
                          Low, Medium, High
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <Stack direction="column" alignItems="center" gap={2}>
                        <StyledCircularProgress
                          progressValue={transactionInfo?.aml_flag}
                          indicatorColor={theme.palette.success.main}
                        />
                        <Typography variant="Body_medium_14" textAlign="center">
                          AML Flags
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Stack direction="column" alignItems="center" gap={2}>
                        <StyledCircularProgress
                          progressValue={transactionInfo?.n_transactions}
                          indicatorColor={theme.palette.success.main}
                        />
                        <Typography variant="Body_medium_14" textAlign="center">
                          No. of Transaction
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </MainCardStyled>
              </Grid>
              <Grid item md={6} xs={12}>
                <MainCardStyled
                  sx={{
                    height: { xs: 'auto', md: '480px', sm: '600px' },
                  }}
                >
                  <Box>
                    <Typography variant="Body_medium_16">
                      Verification
                    </Typography>
                  </Box>

                  <Stack spacing={0.5} mt={2}>
                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Bank account verification'}
                      labelPlacement="end"
                      checked={isCheckVerification?.bankVerification}
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Customer Verification'}
                      checked={isCheckVerification?.customerVerification}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    {/* <FormControlLabel
                    name={""}
                    control={<CustomizedCheckbox />}
                    label={"Financial statement Verification"}
                    labelPlacement="end"
                    sx={{
                      ml: 0,
                      gap: 2,
                    }}
                  /> */}

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Address Verification'}
                      checked={isCheckVerification?.addressVerification}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />
                  </Stack>

                  <Box mt={2}>
                    <Typography variant="Body_medium_16">
                      Source of income
                    </Typography>
                  </Box>

                  <Stack spacing={0.5} mt={2}>
                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Salary'}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Dividends'}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Business income'}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Investments'}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />

                    <FormControlLabel
                      name={''}
                      control={<CustomizedCheckbox />}
                      label={'Others'}
                      labelPlacement="end"
                      sx={{
                        ml: 0,
                        gap: 2,
                      }}
                    />
                  </Stack>
                </MainCardStyled>
              </Grid>
              <Grid item xs={12}>
                <MainCardStyled>
                  <TableWrapper>
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 350,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCellHead>Detail</TableCellHead>
                          <TableCellHead>Date</TableCellHead>
                          <TableCellHead>Description</TableCellHead>
                          <TableCellHead>Category</TableCellHead>
                          <TableCellHead>Amount</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filterTransactionInfo?.length ? (
                          filterTransactionInfo.map((item) => (
                            <>
                              <TableRow hover key={''}>
                                <StyledTableCellData>
                                  {item?.detail}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.description !== 'Total' &&
                                    moment(item?.date).format('DD MMM YYYY')}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.description}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  {item?.category}
                                </StyledTableCellData>
                                <StyledTableCellData>
                                  ₦ {item?.total}
                                </StyledTableCellData>
                              </TableRow>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </MainCardStyled>
              </Grid>
            </Grid>
          </>
        </PageWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProofOfFunds;
