import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const InfoTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_16,
  color: theme.palette.grey.darker,
  display: "inline-block",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_light_14,
  },
}));

const MarkTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_16,
  color: theme.palette.orange.main,
  display: "inline-block",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    ...theme.typography.Body_medium_14,
  },
}));

const PolicyAndTermsText = () => {
  return (
    <>
      <InfoTypography>
        By clicking “Allow” you are permitting E-DocOnline to access account
        Information from your bank account and share it with E-DocOnline. You
        also agree to our{" "}
        <MarkTypography
          onClick={() =>
            window.open(
              "https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+Ng+Terms+%26+Conditions.pdf"
            )
          }
        >
          End User Terms of Use
        </MarkTypography>{" "}
        and{" "}
        <MarkTypography
          onClick={() =>
            window.open(
              "https://s3.eu-west-2.amazonaws.com/dev.e-doconline.co.uk/documents/E-Doc+ng+Privacy+Policy.pdf"
            )
          }
        >
          Privacy Policy
        </MarkTypography>
      </InfoTypography>
    </>
  );
};

export default PolicyAndTermsText;
