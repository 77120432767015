import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// import { ReactComponent as SideMenuIconSvg } from "../../assets/SideMenuWhite.svg";
// import AnimateButton from "../../common/formStyle/AnimateButton";
import SuccessImg from "../../../assets/Success.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  // matchRoutes,
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import MonthlyIncome from "../../../pages/dashboard-ui/Tabs/MonthlyIncome";
import MonthlyExpenses from "../../../pages/dashboard-ui/Tabs/MonthlyExpenses";
import MonthlyAffordability from "../../../pages/dashboard-ui/Tabs/MonthlyAffordability";
import { getUserData } from "../../common/common";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/Logo.svg";
import MyAccount from "../DasboardNav/MyAccount";
import { ElevatorSharp } from "@mui/icons-material";
import Logout from "../../common/logout";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 95;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  minHeight: APPBAR_MOBILE,
  zIndex: 99,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
  },

  [theme.breakpoints.up("md")]: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const BoxWrap = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  display: "flex",
  width: "100%",
  position: "absolute",
  bottom: 0,
  justifyContent: "center",
  padding: theme.spacing(0, 5),
}));

const MainStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const HeaderItemsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: theme.spacing(4),
  },
}));

const UserNameText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_semibold_24,
  },
}));

const UserInfo = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_12,
}));

// ----------------------------------------------------------------------

export default function DashboardUINav({ dashboardData, consent }) {
  let navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [basicAffordability, setBasicAffordability] = useState(false);
  const [fullAffordability, setFullAffordability] = useState(false);
  const [kyc, setKyc] = useState(false);
  const [pof, setPof] = useState(false);
  const [statement, setStatement] = useState(false);
  const [incomeAndJobVerification, setIncomeAndJobVerification] =
    useState(false);

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    let allProducts = [];
    consent?.forEach((v) => {
      if (v?.consent?.data?.products.length && v?.consent?.data?.products) {
        allProducts.push(v?.consent?.data?.products);
      }
    });
    allProducts = allProducts.flat(2);
    // allProducts.splice(1, 1);
    allProducts?.filter((item) => {
      if (item.code === "affordability_basic") {
        setBasicAffordability(true);
      }
      if (item.code === "affordability_full") {
        setFullAffordability(true);
        setBasicAffordability(true);
        setStatement(true);
      }
      if (
        item.code === "statement_basic" ||
        (item.code === "statement_plus" && item?.months?.length)
      ) {
        setStatement(true);
      }
      if (item.code === "kyc") {
        setKyc(true);
      }
      if (item.code === "pof") {
        setPof(true);
      }
    });
  }, [consent]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    let userData = getUserData();
    setUserData(userData);
  }, []);
  const theme = useTheme();

  const [openIncome, setOpenIncome] = useState(false);
  const handleClickOpenIncomeDialog = () => {
    setOpenIncome(true);
  };
  const handleCloseIncomeDialog = () => {
    setOpenIncome(false);
  };

  const [openExpenses, setOpenExpenses] = useState(false);
  const handleClickOpenExpensesDialog = () => {
    setOpenExpenses(true);
  };
  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const [openAffordability, setOpenAffordability] = useState(false);
  const handleClickOpenAffordabilityDialog = () => {
    setOpenAffordability(true);
  };
  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    let userData = getUserData();
    setUserData(userData);
  }, []);

  const isActiveRoot = useLocation();

  const ListItemStyle = styled((props) => <ListItemButton {...props} />)(
    ({ theme }) => ({
      ...theme.typography.Body_medium_14,
      position: "relative",
      textAlign: "center",
      textTransform: "initial",
      padding: theme.spacing(2, 1),
      margin: theme.spacing(0.5, 1),
      color: theme.palette.common.white,
      borderBottom: `2px solid transparent`,
      maxHeight: 66,
    })
  );
  const activeRootStyle = {
    color: theme.palette.orange.main,
    borderBottom: `2px solid ${theme.palette.orange.main}`,
    "&:before": { display: "block" },
    "&:hover": {
      color: theme.palette.orange.main,
    },
  };

  const displayDesktop = () => {
    return (
      <>
        <BoxWrap>
          <MainStyle>
            {userDetails}
            {headerItems}
          </MainStyle>
        </BoxWrap>
      </>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box component="img" src={Logo} alt="Logo" width={94} height={41} />

          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                display: { lg: "none" },
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>

            <IconButton
              {...{
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog
          {...{
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          PaperProps={{
            sx: { width: "100%" },
          }}
        >
          <Stack direction="column" alignItems="center" gap={3}>
            <Box
              py={4}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #002E45",
              }}
            >
              <MyAccount />
            </Box>

            {headerItems}
          </Stack>
        </Dialog>

        {isActiveRoot.pathname === "/dashboard-overview" && (
          <Box m={4}>{userDetails}</Box>
        )}
      </>
    );
  };

  const userDetails = (
    <Stack direction="row" alignItems="center">
      <Box component={RouterLink} to="/home" sx={{ color: "white" }}>
        <ChevronLeftIcon sx={{ cursor: "pointer" }} />
      </Box>

      <Stack
        direction="column"
        alignItems="start"
        justifyContent="center"
        ml={2}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <UserNameText>
            {userData?.firstName} {userData?.lastName}
          </UserNameText>
          <Box component="img" src={SuccessImg} />
        </Stack>
        {/* <UserInfo >
                    Amoueq Oscae, Open banking date fetched 04, Aug 2022 . 3:15
                  </UserInfo> */}
      </Stack>
    </Stack>
  );

  const headerItems = (
    <HeaderItemsWrapper>
      {basicAffordability && (
        <>
          <ListItemStyle
            to="/dashboard-overview"
            component={RouterLink}
            sx={{
              ...(isActiveRoot.pathname === "/dashboard-overview" &&
                activeRootStyle),
            }}
          >
            Overview
          </ListItemStyle>
          {/* <ListItemStyle onClick={handleClickOpenIncomeDialog}>
            Income
          </ListItemStyle>
          <ListItemStyle onClick={handleClickOpenExpensesDialog}>
            Expenses
          </ListItemStyle>
          <ListItemStyle onClick={handleClickOpenAffordabilityDialog}>
            Affordability
          </ListItemStyle> */}
        </>
      )}
      {fullAffordability && (
        <>
          <ListItemStyle
            to="/risk-and-insight"
            component={RouterLink}
            sx={{
              ...(isActiveRoot.pathname === "/risk-and-insight" &&
                activeRootStyle),
            }}
          >
            Risk
          </ListItemStyle>
          <ListItemStyle
            to="/category-insights"
            component={RouterLink}
            sx={{
              ...(isActiveRoot.pathname === "/category-insights" &&
                activeRootStyle),
            }}
          >
            Category Insights
          </ListItemStyle>
        </>
      )}
      {statement && (
        <ListItemStyle
          to="/statement"
          component={RouterLink}
          sx={{
            ...(isActiveRoot.pathname === "/statement" && activeRootStyle),
          }}
        >
          Statement
        </ListItemStyle>
      )}
    {kyc && (
      <ListItemStyle
        to="/income-and-job-verification"
        component={RouterLink}
        sx={{
          ...(isActiveRoot.pathname === "/income-and-job-verification" &&
            activeRootStyle),
        }}
      >
        KYC
      </ListItemStyle>
    )} 
    {pof && (
        <ListItemStyle
        to="/poof-of-funds"
        component={RouterLink}
        sx={{
          ...(isActiveRoot.pathname === "/poof-of-funds" &&
            activeRootStyle),
        }}
      >
        Proof Of Funds
      </ListItemStyle>
    )}
      {/* <ListItemStyle
        to="/documents"
        component={RouterLink}
        sx={{
          ...(isActiveRoot.pathname === "/documents" && activeRootStyle),
        }}
      >
        Documents
      </ListItemStyle> */}

      <ListItemStyle>
        <Logout />
      </ListItemStyle>
    </HeaderItemsWrapper>
  );

  return (
    <>
      <RootStyle>
        <>{mobileView ? displayMobile() : displayDesktop()}</>
      </RootStyle>

      <MonthlyIncome
        open={openIncome}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseIncomeDialog}
      />
      <MonthlyExpenses
        open={openExpenses}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseExpensesDialog}
      />
      <MonthlyAffordability
        open={openAffordability}
        dashboardData={dashboardData}
        handleCloseDialog={handleCloseAffordabilityDialog}
      />
    </>
  );
}
