import { styled, TableCell, TableRow } from "@mui/material";

export const TableRows = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.lighter}`,
  borderBottom: `1px solid ${theme.palette.primary.lighter}`,
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  border: "none",
  ...theme.typography.Body_regular_10,
  color: theme.palette.grey.light,
  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_medium_12,
  },
}));

export const TableCellData = styled(TableCell)(({ theme }) => ({
  border: "none",
  ...theme.typography.Body_light_14,
}));

export const TableCellDataDashboard = styled(TableCell)(({ theme }) => ({
  border: "none",
  ...theme.typography.Body_semibold_16,
}));
