import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Loader = () => {
  return (
    <>
      <Box
        sx={{
          height: "80vh",
          gap: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 0,
        }}
      >
        <CircularProgress
          sx={{
            color: "orange.main",
          }}
          thickness={1}
        />
      </Box>
    </>
  );
};

export default Loader;
