import axios from "axios";
import { getUserData } from "./common";
const BASE_URL = process.env.REACT_APP_EDOC_API_URL || "";
export default function GetAxiosInstance(
  link,
  method,
  payload,
  headers,
  token
) {
  let userData = getUserData();
  let requestObj = {
    method: method,
    url: `${BASE_URL}/${link}`,
    data: payload,
    headers: {
      Authorization: token || `Bearer ${userData?.accessToken}`,
      ...headers,
    },
  };
  return axios(requestObj)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // if (link !== "auth/login") {
      //   if (err?.response?.status === 401) {
      //     window.location.href = "/login";
      //   }
      // }
      return err;
    });
}
