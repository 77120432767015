import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const RootStyle = styled(Box)({
  height: "100%",
  margin: 0,
});

export const FromWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  width: "100%",
  // height: "100%",

  [theme.breakpoints.up("sm")]: {
    margin: 0,
  },
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    minHeight: "90vh",
  },
}));

export const LoaderStyle = styled("div")(({ theme }) => ({
  margin: "auto",

}));
