import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./components/common/ScrollToTop";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "react-phone-input-2/lib/style.css";
import Toast from "./components/common/Toast";
import TagManager from "react-gtm-module";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-P2N4LK3",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <Router />
        <Toast />
      </ThemeConfig>
    </BrowserRouter>
  );
}

export default App;
