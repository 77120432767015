import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardUINav from "../Navbar/DashboardUINav/DashboardUINav";
import SideMenuIcon from "../sidebar/SideMenuIcon";
// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: 80,
  [theme.breakpoints.up("lg")]: {
    paddingTop: 100,
  },
}));

// ----------------------------------------------------------------------

export function DashboardUI() {
  return (
    <>
      <RootStyle>
        {/* <DashboardUINav /> */}
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
