import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "../../components/common/MainCard";
//

export const PageBoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 5, 0, 5),
  },
}));

export const CardInfoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  flexWrap: "wrap",
  // textAlign: "center",
  gap: 15,
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    textAlign: "start",
  },
}));
//

// Manage Plan Style
export const PermissionsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));

export const PermissionsDetailsBox = styled(Box)(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  marginBottom: theme.spacing(4),
}));

export const CardInfoTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  marginBottom: 20,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_24,
    marginBottom: 10,
  },
}));

export const CardInfoSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const PermissionTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_16,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_28,
  },
}));

export const PermissionDesc = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,
  color: theme.palette.grey.darker,
}));

export const CurrentDataTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_16,
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_20,
  },
}));

export const BoxTextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  marginLeft: theme.spacing(1.5),
  gap: 1.25,
}));

export const BoxText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  color: theme.palette.grey.darker,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_medium_18,
  },
}));

export const BoxDesc = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,
  color: theme.palette.grey.darker,
  marginTop: "10px",

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  gap: theme.spacing(2),

  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

export const ButtonCard = styled(MainCard)(({ theme }) => ({
  background: "transparent",
  boxShadow: "none",
  [theme.breakpoints.up("md")]: {
    background: theme.palette.primary.light,
  },
}));

export const PlanStepTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  marginBottom: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_32,
  },
}));

export const PlanDescCommon = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_light_14,
  marginBottom: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.grey.darker,
  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_light_16,
  },
}));

export const CancelPlanDescWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(1.5),

  [theme.breakpoints.up("md")]: {
    alignItems: "center",
  },
}));

export const PlanTitleCommon1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_16,

  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_semibold_20,
  },
}));

export const PlanDescCommon1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  color: theme.palette.grey.darker,
  [theme.breakpoints.up("md")]: {
    ...theme.typography.Body_medium_16,
  },
}));

export const ConfirmCancelDescWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

//
export const Abc = styled(Box)(({ theme }) => ({}));
export const UserImage = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {},
}));
