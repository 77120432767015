import { Box, Typography, Button } from "@mui/material";
import React from "react";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
} from "../../pages/Auth/AuthStyle";
import { Link as RouterLink } from "react-router-dom";

import SuccessLogo from "../../assets/Success.svg";
import AnimateButton from "./formStyle/AnimateButton";

const SuccessModal = (props) => {
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="box">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                src={SuccessLogo}
                height={85}
                width={85}
                mb={5}
              />

              <Typography variant="Body_semi_32" mb={3} textAlign="center">
                {props.successMsg}
                <br />
                {props.success}
              </Typography>

              <Typography variant="Body_light_16" mb={5} textAlign="center">
                {props.subTitle}
                <br />
                {props.subTitle1}
              </Typography>
            </Box>
            {props.btnName && (
              <AnimateButton>
                <Button
                  size="large"
                  onClick={(e) => {
                    return props.handleClick();
                  }}
                  type="submit"
                  variant="contained"
                  sx={{ width: '100%' }}
                >
                  {props.btnName}
                </Button>
              </AnimateButton>
            )}
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};

export default SuccessModal;
