const FONT_PRIMARY = "Inter";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  Header_light_104: {
    fontWeight: 300,
    fontSize: "104px",
    lineHeight: "126px",
  },

  Header_light_88: {
    fontWeight: 300,
    fontSize: "88px",
    lineHeight: "106px",
  },

  Header_light_56: {
    fontWeight: 300,
    fontSize: "56px",
    lineHeight: "68px",
  },

  Header_light_48: {
    fontWeight: 300,
    fontSize: "48px",
    lineHeight: "58px",
  },

  Header_light_32: {
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "39px",
  },

  Header_semibold_104: {
    fontWeight: 600,
    fontSize: "104px",
    lineHeight: "126px",
  },

  Header_semibold_88: {
    fontWeight: 600,
    fontSize: "88px",
    lineHeight: "106px",
  },

  Header_semibold_56: {
    fontWeight: 600,
    fontSize: "56px",
    lineHeight: "68px",
  },

  Header_semibold_48: {
    fontWeight: 600,
    fontSize: "48px",
    lineHeight: "58px",
  },

  Header_semibold_32: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "39px",
  },

  Header_black_104: {
    fontWeight: 900,
    fontSize: "104px",
    lineHeight: "126px",
  },

  Header_black_88: {
    fontWeight: 900,
    fontSize: "88px",
    lineHeight: "106px",
  },

  Header_black_56: {
    fontWeight: 900,
    fontSize: "56px",
    lineHeight: "68px",
  },

  Header_black_48: {
    fontWeight: 900,
    fontSize: "48px",
    lineHeight: "58px",
  },

  Header_black_32: {
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "39px",
  },

  Body_light_32: {
    fontWeight: 300,
    fontSize: "32px",
    lineHeight: "39px",
  },

  Body_light_28: {
    fontWeight: 300,
    fontSize: "28px",
    lineHeight: "38px",
  },

  Body_light_24: {
    fontWeight: 300,
    fontSize: "24px",
    lineHeight: "38px",
  },

  Body_light_20: {
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "28px",
  },

  Body_light_18: {
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "24px",
  },

  Body_light_16: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
  },

  Body_light_14: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "18px",
  },

  Body_light_12: {
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "18px",
  },

  Body_medium_32: {
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "39px",
  },

  Body_medium_28: {
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "38px",
  },

  Body_medium_24: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "34px",
  },

  Body_medium_20: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
  },

  Body_medium_18: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
  },

  Body_medium_16: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },

  Body_medium_14: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },

  Body_medium_12: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
  },

  Body_semibold_32: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "38.73px",
  },

  Body_semibold_28: {
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "34px",
  },

  Body_semibold_24: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29.05px",
  },

  Body_semibold_20: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24.2px",
  },

  Body_semibold_18: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "21.78px",
  },

  Body_semibold_16: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
  },

  Body_semibold_14: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
  },

  Body_semibold_12: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
  },

  Body_light_10: {
    fontWeight: 300,
    fontSize: "10px",
    lineHeight: "20px",
  },

  Body_regular_10: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "20px",
  },

  Body_semibold_10: {
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "20px",
  },

  Body_black_10: {
    fontWeight: 900,
    fontSize: "10px",
    lineHeight: "20px",
  },
};

export default typography;
