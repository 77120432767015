import { Box, Typography } from '@mui/material';
import React from 'react';
import './passwordStrenth.css';

const PasswordStrengthMeter = (props) => {
  const testedResult = props.password;
  const createPasswordLabel = () => {
    let score = 0;
    let regexPositive = ['.{1,8}', '.{8,12}'];
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(testedResult)) {
        score += 1;
      }
    });
    switch (score) {
      case 0:
        return {
          value: 0,
          info: '',
        };
      case 1:
        return {
          value: 1,
          info: 'Weak',
        };
      case 2:
        return {
          value: 2,
          info: 'Strong',
        };
      default:
        return null;
    }
  };
  // eslint-disable-next-line no-lone-blocks
  {
    props.actions(createPasswordLabel().info);
  }

  return (
    <>
      <Box component={'div'} className="password-strength-meter">
        <progress
          className={`password-strength-meter-progress strength-${
            createPasswordLabel().info
          }`}
          value={createPasswordLabel().value}
          max="2"
        />
        <br />
        <Typography
          variant="Body_light_12"
          my={1}
          className="password-strength-meter-label"
        >
          Password strength
        </Typography>
      </Box>
    </>
  );
};
export default PasswordStrengthMeter;
