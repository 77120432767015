import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const StyledCircularProgress = (props) => {
  let {
    size = 100,
    progressValue = '',
    type = '',
    selectMonth,
    selectedEndDate = '',
    trackWidth = 6,
    trackColor = `#001823`,
    indicatorWidth = 6,
    indicatorCap = `round`,
    labelColor = `#fff`,
    fontSize,
  } = props;
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(progressValue);
  }, [progressValue]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - trackWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <Box
      component={'div'}
      className="svg-pi-wrapper"
      style={{ width: size, height: size }}
    >
      {type !== 'noCirculation' && (
        <svg className="svg-pi" width={size} height={size} viewBox={viewBox}>
          <circle
            fill="transparent"
            stroke={trackColor}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${trackWidth}px`}
          />
          <circle
            fill="transparent"
            stroke={props.indicatorColor}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${indicatorWidth}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={[dash, circumference - dash]}
            strokeLinecap="round"
            style={{ transition: 'all 0.5s' }}
          />
        </svg>
      )}
      <Box
        component={'div'}
        className="svg-pi-label"
        style={{ color: labelColor }}
      >
        <span className="svg-pi-label__progress" style={{ fontSize: fontSize }}>
          {type !== 'noCirculation'
            ? type === 'percentage'
              ? `${props.progressValue}%`
              : props.progressValue
            : `${props.progressValue} / ${selectMonth || 1}`}
        </span>
      </Box>
    </Box>
  );
};

export default StyledCircularProgress;
