import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardNav from "../Navbar/DasboardNav/DashboardNav";
import DashboardSidebar from "../sidebar/DashboardSidebar";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  width: "100%",
  justifyContent: "center",

  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-end",
  },
}));

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: 90,
  maxWidth: "100%",

  [theme.breakpoints.up("lg")]: {
    paddingTop: 120,
  },
}));

// ----------------------------------------------------------------------

export function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const isActiveRoot = useLocation();

  return (
    <>
      <RootStyle>
        <DashboardNav onOpenDashboardSidebar={() => setOpen(true)} />
        {isActiveRoot.pathname === "/dashboard" && (
          <DashboardSidebar
            state={state}
            isOpenDashboardSidebar={open}
            onCloseDashboardSidebar={() => setOpen(false)}
          />
        )}

        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
