import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const RootStyle = styled(Box)({
  height: "100%",
  margin: 0,
});

export const FromWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  width: "100%",
  // height: "100%",

  [theme.breakpoints.up("sm")]: {
    margin: 0,
  },
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    minHeight: "90vh",
  },
}));

export const FormStyle = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(4, 3),
  margin: theme.spacing(0, 1),
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    border: `2px solid ${theme.palette.primary.light}`,
    margin: theme.spacing(0),
    width: "550px",
    padding: theme.spacing(6, 3.75),
  },
}));

export const FormInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "28px 0",
  marginBottom: theme.spacing(4),
}));

export const FormInputWrapper = styled(FormInfoBox)(({ theme }) => ({
  gap: theme.spacing(4, 0),
}));

export const GuestBg = styled(FromWrapper)(({ theme }) => ({
  background: 'transparent',

  [theme.breakpoints.up('sm')]: {
    background: `linear-gradient(to right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 50%)`,
  },
}));