import { Navigate, Route, useNavigate, useRoutes } from "react-router-dom";
import { MainLayout } from "./components/Layout/MainLayout";
import { LoginLayout } from "./components/Layout/LoginLayout";
import { NoLayout } from "./components/Layout/NoLayout";

import { EmailVerification } from "./pages/Auth/EmailVerification";
import { Login } from "./pages/Auth/Login";
import { PasswordSetup } from "./pages/Auth/PasswordSetup";
import { Signup } from "./pages/Auth/Signup";
import { AccountSetup } from "./pages/Auth/AccountSetup";
import AccountSuccsess from "./pages/Auth/SuccsessModal/AccountSuccsess";
import EmailVerificationSuccess from "./pages/Auth/SuccsessModal/EmailVerificationSuccess";
import PasswordResetSuccess from "./pages/Auth/SuccsessModal/PasswordResetSuccess";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";

import Home from "./pages/Home";

import Permission from "./pages/permission";
import AccountInformation from "./pages/permission/AccountInformation";
import ExternalAccountInformation from "./pages/permission/ExternalAccountInformation";

import BankSeleciton from "./pages/bank-selection";
import ConsentSuccess from "./pages/bank-selection/ConsentSuccess";
import ExternalConsentSuccess from "./pages/bank-selection/ExternalConsentSuccess";


import Plans from "./pages/plans";
import CancelPlan from "./pages/plans/CancelPlan";
import ConfirmCancellation from "./pages/plans/ConfirmCancellation";
import CancellationComplete from "./pages/plans/CancellationComplete";
import { DashboardLayout } from "./components/Layout/DashboardLayout";
import Dashboard from "./pages/dashboard";
import SelectPartner from "./pages/partner/SelectPartner";
import Overview from "./pages/dashboard-ui/Overview";
import { DashboardUI } from "./components/Layout/DashboardUI";
import RiskAndInsight from "./pages/risk-and-insight";
import { ChangePassword } from "./pages/Auth/changePassword";
import CategoryInsights from "./pages/category-insights";
import PendingApproval from "./pages/bank-selection/PendingApproval";
import { GuestLogin } from "./pages/guest/guestLogin";
import { AppConsentAuth } from "./pages/app-consent-auth/appConsentAuth";
import ExpandList from "./pages/category-insights/ExpandList";
import Statement from "./pages/statement";
import IncomeAndJobVerification from "./pages/IncomeAndJobVerification";
import UnlockAccount from "./pages/Auth/UnlockAccount";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import ResetAccount from "./pages/Auth/ResetAccount";
import VerifyOTP from "./pages/bank-selection/VerifyOTP";
import ProofOfFunds from "./pages/ProofOfFunds";
import ExternalDemo from './pages/externalDemo';
import { Confirm } from "./pages/Auth/changePassword/confirm";
import DirectDebit from './pages/permission/DirectDebit';
import DirectDebitSuccess from './pages/permission/DirectDebitSuccerss';

// ----------------------------------------------------------------------

export default function Router() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   // let userData = getUserData();
  //   // if (!userData) {
  //   //   navigate("/login");
  //   // }
  // }, []);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        { path: '/permission', element: <Permission /> },
        { path: '/account-information', element: <AccountInformation /> },
        { path: '/direct-debit/:id', element: <DirectDebit /> },
        { path: '/bank-seleciton', element: <BankSeleciton /> },
      ],
    },
    {
      path: '/',
      element: <LoginLayout />,
      children: [
        { path: '/login', element: <Login /> },
        { path: '/signup', element: <Signup /> },
        { path: '/account-unlock', element: <UnlockAccount /> },
        { path: '/verify-email', element: <VerifyAccount /> },
        { path: '/reset-password', element: <ResetAccount /> },
        { path: '/direct-success', element: <DirectDebitSuccess /> },

        { path: '/account-setup', element: <AccountSetup /> },
        { path: '/password-setup', element: <PasswordSetup /> },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '/email-verification', element: <EmailVerification /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/confirm', element: <Confirm /> },
        // Guest
        { path: '/consent-request/partner/:id', element: <GuestLogin /> },
        { path: '/mandate-request/partner/:id', element: <GuestLogin /> },

        // { path: "/consent-request/external/:consentId/auth/:publicKey", element: <AppConsentAuth /> },
      ],
    },

    {
      path: '/',
      element: <NoLayout />,
      children: [
        { path: '/home', element: <Home /> },

        { path: '/consent-success', element: <ConsentSuccess /> },
        {
          path: '/consent-request/:externalAppConsentId/consent-success',
          element: <ExternalConsentSuccess />,
        },
        { path: '/cancel-plan', element: <CancelPlan /> },
        { path: '/confirm-cancellation', element: <ConfirmCancellation /> },
        { path: '/cancellation-complete', element: <CancellationComplete /> },

        { path: '/account-created', element: <AccountSuccsess /> },
        { path: '/password-reset-success', element: <PasswordResetSuccess /> },
        {
          path: '/email-verification-success',
          element: <EmailVerificationSuccess />,
        },
        {
          path: '/pending-approval',
          element: <PendingApproval />,
        },
        { path: '/verify-otp', element: <VerifyOTP /> },
        {
          path: '/consent-request/external/:externalAppConsentId/auth/:publicKey',
          element: <ExternalAccountInformation />,
        },
      ],
    },

    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/view-plans', element: <Plans /> },
        { path: '/select-partner', element: <SelectPartner /> },
      ],
    },

    {
      path: '/',
      element: <DashboardUI />,
      children: [
        {
          path: '/dashboard-overview',
          element: <Overview />,
        },
        { path: 'risk-and-insight', element: <RiskAndInsight /> },
        { path: 'category-insights', element: <CategoryInsights /> },
        { path: 'expand-list', element: <ExpandList /> },
        { path: 'statement', element: <Statement /> },
        {
          path: 'income-and-job-verification',
          element: <IncomeAndJobVerification />,
        },
        {
          path: 'poof-of-funds',
          element: <ProofOfFunds />,
        },
        {
          path: 'external-demo',
          element: <ExternalDemo />,
        },
        // {
        //   path: "documents",
        //   element: <Documents />,
        // },
      ],
    },

    { path: '*', element: <Navigate to="/home" replace /> },
  ]);
}
