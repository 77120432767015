import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.Body_medium_14,
  position: "relative",
  textAlign: "center",
  textTransform: "initial",
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(0.5, 2.5),
  color: theme.palette.common.white,
  borderBottom: `2px solid transparent`,
}));

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path } = item;

  const activeRootStyle = {
    color: theme.palette.orange.main,
    borderBottom: `2px solid ${theme.palette.orange.main}`,
    "&:before": { display: "block" },
    "&:hover": {
      color: theme.palette.orange.main,
    },
  };

  const list_item = (
    <>
      <ListItemText disableTypography primary={title} />
    </>
  );

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      {list_item}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <StyledList>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </StyledList>
    </Box>
  );
}
