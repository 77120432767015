import Bank1 from "../assets/Bank1.png";
import Bank2 from "../assets/Bank2.png";
import Bank3 from "../assets/Bank3.png";
import Bank4 from "../assets/Bank4.png";
import Bank5 from "../assets/Bank5.png";
import Wema from "../assets/Wema.png";
import Providus from "../assets/providus.png";
import Globus from "../assets/globus.png";
import Polaris from "../assets/polaris.png";
import Keystone from "../assets/KeyStone.png";
import VFD from "../assets/VFD.jpg";

const bankList = [
  {
    id: "1",
    title: "WEMA Bank Plc",
    name: process.env.REACT_APP_WEMA_KEY,
    images: `${Wema}`,
    enabled:true
  },
  {
    id: "2",
    title: "Providus",
    name: "providus",
    images: `${Providus}`,
    enabled:true
  },
  {
    id: "3",
    title: "Polaris",
    name: "polaris",
    images: `${Polaris}`,
    enabled:true
  },
  {
    id: "7",
    title: "Sterling Bank",
    name: "sterling",
    images: `${Bank2}`,
    enabled:true
  },
  {
    id: "4",
    title: "Globus",
    name: "Globus",
    images: `${Globus}`,
    enabled:false
  },
  {
    id: "5",
    title: "Keystone",
    name: "Keystone",
    images: `${Keystone}`,
    enabled:false
  },
  {
    id: "6",
    title: "VFD",
    name: "VFD",
    images: `${VFD}`,
    enabled:false
  },
  // {
  //   id: "4",
  //   title: "Access Bank",
  //   name: "access",
  //   images: `${Bank3}`,
  // },
  // {
  //   id: "5",
  //   title: "Guaranty Trust Bank",
  //   name: "guarantyTrust",
  //   images: `${Bank4}`,
  // },
  // {
  //   id: "6",
  //   title: "Stanbic IBTC Bank",
  //   name: "stanbicIBTC",
  //   images: `${Bank5}`,
  // },
  // {
  //   id: "7",
  //   title: "NIBSS Plc",
  //   name: "nibss",
  //   images: `${Bank1}`,
  // },
];

export default bankList;
