import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../../components/common/SuccessModal";

const AccountSuccess = () => {
  let navigate = useNavigate();
  return (
    <>
      <SuccessModal
        successMsg="Account created"
        success="successfully"
        subTitle="Your account has been created, Proceed to login"
        btnName="continue"
        handleClick={() => {
          navigate('/login');
        }}
      />
    </>
  );
};

export default AccountSuccess;
