import styled from "@emotion/styled";
import {
  Box,
  CardContent,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  PageSubTitle,
  PageTitle,
  PageWrapper,
  TitleWrapper,
  InfoWrapper,
  CommonWrapper,
  ReadPermissionTitle,
  PermissionCardSubText,
  PermissionCardMainText,
  CancelButton,
  SubmitButton,
  CostForService,
  LabelDescription,
  OtherText,
  ButtonWrapper,
} from "./PermissionStyle";
import CloseIcon from "@mui/icons-material/Close";
import MainCard from "../../components/common/MainCard";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import RoundedCheckBoxStyled from "../../components/common/formStyle/RoundedCheckBoxStyled";

import AccountIcon from "../../assets/Account.svg";
import TransactionsIcon from "../../assets/Transactions.svg";
import StatementIcon from "../../assets/Statement.svg";
import BalanceIcon from "../../assets/Balance.svg";
import { FillButton, OutlineButton } from "../../components/common/style";
import { toast } from "react-toastify";
import PolicyAndTermsText from "../../components/common/PolicyAndTermsText";
//
const ExpandMore1 = styled((props) => {
  const { expand1, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(135deg)" : "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore2 = styled((props) => {
  const { expand2, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(135deg)" : "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
//
const Permission = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [expanded1, setExpanded1] = React.useState(true);
  const [expanded2, setExpanded2] = React.useState(true);
  const [permission, setPermission] = React.useState([]);

  const handleExpandClick = () => {
    setExpanded1(!expanded1);
  };

  const handleExpandClick1 = () => {
    setExpanded2(!expanded2);
  };

  const handlePermission = (e) => {
    let transactionsPermission = [
      "ReadTransactionsCredits",
      "ReadTransactionsDebits",
      "ReadTransactionsDetail",
    ];
    if (e.target.value === "YourTransactions") {
      transactionsPermission.forEach((i) => {
        if (e.target.checked) {
          permission.push(i);
        } else {
          let findIndex = permission.indexOf(e.target.name);
          permission.splice(findIndex, 1);
        }
      });
    } else {
      if (e.target.checked) {
        permission.push(e.target.name);
      } else {
        let findIndex = permission.indexOf(e.target.name);
        permission.splice(findIndex, 1);
      }
    }
    setPermission(permission);
  };
  const handleAllow = (e) => {
    if (permission.length) {
      navigate("/account-information", { state: permission });
    } else {
      toast.error("Kindly select a permission to proceed");
    }
  };
  return (
    <>
      <PageWrapper>
        <TitleWrapper pb={9}>
          <PageTitle>Permission to request access</PageTitle>

          <PageSubTitle>
            For you to use this service E-DocOnline needs to access information
            from your accounts at your bank.
          </PageSubTitle>
        </TitleWrapper>

        <InfoWrapper>
          <MainCard sx={{ width: "100%", backgroundColor: "primary.color1" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="Body_medium_20" color="orange.main">
                Why we need your data
              </Typography>
              <ExpandMore1
                expand={expanded1}
                onClick={handleExpandClick}
                aria-expanded={expanded1}
                aria-label="show more"
              >
                <CloseIcon sx={{ fill: theme.palette.orange.main }} />
              </ExpandMore1>
            </CardContent>

            <Collapse in={expanded1}>
              <CardContent>
                <Typography variant="Body_light_16" color="common.white">
                  E-doc will securely access your Bank Information to retrieve
                  your financial data.
                  <br />
                  E-DocOnline has read-only access to your bank account
                </Typography>
              </CardContent>
            </Collapse>
          </MainCard>

          <MainCard sx={{ width: "100%", backgroundColor: "primary.color1" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="Body_medium_20" color="orange.main">
                The cost for this service
              </Typography>
              <ExpandMore2
                expand={expanded2}
                onClick={handleExpandClick1}
                aria-expanded={expanded2}
                aria-label="show more"
              >
                <CloseIcon sx={{ fill: theme.palette.orange.main }} />
              </ExpandMore2>
            </CardContent>

            <Collapse in={expanded2}>
              <CardContent
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "18px",
                }}
              >
                <CostForService>
                  <Typography variant="Body_light_14">Statement</Typography>
                  <Typography variant="Body_semibold_16">₦25</Typography>
                </CostForService>

                <CostForService>
                  <Typography variant="Body_light_14">Balance</Typography>
                  <Typography variant="Body_semibold_16">₦25</Typography>
                </CostForService>

                <CostForService>
                  <Typography variant="Body_light_14">
                    Affordability assessment{" "}
                  </Typography>
                  <Typography variant="Body_semibold_16">₦25</Typography>
                </CostForService>
              </CardContent>
            </Collapse>
          </MainCard>
        </InfoWrapper>

        {/*  */}
        <CommonWrapper>
          <ReadPermissionTitle>
            E-DocOnline needs your permission to read
          </ReadPermissionTitle>

          {/*  */}
          <Box mt={4}>
            <>
              <FormControl>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 7.25,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "start" }}>
                    <Box component="img" src={AccountIcon} alt="account" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        ml: 1.5,
                        gap: 1.25,
                      }}
                    >
                      <FormControlLabel
                        value="YourAccounts"
                        control={<RoundedCheckBoxStyled />}
                        name="ReadAccountsDetail"
                        label="Your accounts"
                        labelPlacement="start"
                        sx={{
                          ml: 0,
                          alignItems: "start",
                        }}
                        onChange={handlePermission}
                      />
                      <LabelDescription>
                        For you to use this service E-DocOnline needs to access
                        information from your accounts at your bank.
                      </LabelDescription>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "start" }}>
                    <Box component="img" src={TransactionsIcon} alt="account" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        ml: 1.5,
                        gap: 1.25,
                      }}
                    >
                      <FormControlLabel
                        value="YourTransactions"
                        control={<RoundedCheckBoxStyled />}
                        // name="YourTransactions"
                        label="Your transactions"
                        labelPlacement="start"
                        sx={{
                          ml: 0,
                          alignItems: "start",
                        }}
                        onChange={handlePermission}
                      />
                      <LabelDescription>
                        For you to use this service E-DocOnline needs to access
                        information from your accounts at your bank.
                      </LabelDescription>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "start" }}>
                    <Box component="img" src={StatementIcon} alt="account" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        ml: 1.5,
                        gap: 1.25,
                      }}
                    >
                      <FormControlLabel
                        value="YourStatement"
                        name="ReadStatements"
                        control={<RoundedCheckBoxStyled />}
                        label="Your statement"
                        labelPlacement="start"
                        sx={{
                          ml: 0,
                          alignItems: "start",
                        }}
                        onChange={handlePermission}
                      />
                      <LabelDescription>
                        For you to use this service E-DocOnline needs to access
                        information from your accounts at your bank.
                      </LabelDescription>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "start" }}>
                    <Box component="img" src={BalanceIcon} alt="account" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        ml: 1.5,
                        gap: 1.25,
                      }}
                    >
                      <FormControlLabel
                        value="YourBalances"
                        name="ReadBalances"
                        control={<RoundedCheckBoxStyled />}
                        label="Your balances"
                        labelPlacement="start"
                        sx={{
                          ml: 0,
                          alignItems: "start",
                        }}
                        onChange={handlePermission}
                      />
                      <LabelDescription>
                        For you to use this service E-DocOnline needs to access
                        information from your accounts at your bank.
                      </LabelDescription>
                    </Box>
                  </Box>
                </Box>
              </FormControl>
            </>
          </Box>

          {/*  */}
          {/* <Grid container spacing={4} my={9}>
            <Grid item xs={12} md={6}>
              <MainCard>
                <Stack spacing={1.2} p={2}>
                  <PermissionCardMainText>
                    You are sharing your data for
                  </PermissionCardMainText>
                  <PermissionCardSubText>90 days</PermissionCardSubText>{" "}
                </Stack>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <MainCard>
                <Stack spacing={1.2} p={2}>
                  <PermissionCardMainText>
                    We will add your data until
                  </PermissionCardMainText>
                  <PermissionCardSubText>25th Jun 2022</PermissionCardSubText>{" "}
                </Stack>
              </MainCard>
            </Grid>
          </Grid> */}

          <OtherText textAlign="center" color="grey.darker" mt={9}>
            Continue to your bank to allow to access this data under these
            terms?
          </OtherText>

          <ButtonWrapper>
            <Box sx={{ width: "inherit" }}>
              <AnimateButton>
                <OutlineButton
                  size="large"
                  type="submit"
                  sx={{ width: "-webkit-fill-available" }}
                  variant="outlined"
                  component={RouterLink}
                  to="/home"
                >
                  Back
                </OutlineButton>
              </AnimateButton>
            </Box>

            <Box sx={{ width: "inherit" }}>
              <AnimateButton>
                <FillButton
                  size="large"
                  type="submit"
                  sx={{ width: "-webkit-fill-available" }}
                  variant="contained"
                  onClick={handleAllow}
                >
                  Allow
                </FillButton>
              </AnimateButton>
            </Box>
          </ButtonWrapper>

          <PolicyAndTermsText />
        </CommonWrapper>
      </PageWrapper>
    </>
  );
};

export default Permission;
