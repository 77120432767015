import React from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../components/common/SuccessModal';

const DirectDebitSuccess = () => {
  let navigate = useNavigate();
  return (
    <>
      <SuccessModal
        successMsg="Mandate Successfully Created"
        success="successfully"
        subTitle="You have successfully created your mandate"
        btnName=""
        handleClick={() => {
          navigate('/login');
        }}
      />
    </>
  );
};

export default DirectDebitSuccess;
