export const getUserData = () => {
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);
  return userData;
};

export const removeUserData = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("authUser");
};


export const sendEventToExternalApp = (eventType, eventData) => {
  if (window.opener) {
    window.opener.postMessage(JSON.stringify({
      eventType,
      eventData
    }), '*');
  }
}