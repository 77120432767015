import { Box, Button, Menu, MenuItem, Container, Grid } from "@mui/material";
import React, { useState , useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Popover from "@mui/material/Popover";

import Logo from "../../assets/Logo.svg";
import Auth from "../../assets/Auth.svg";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import MainCard from "../../components/common/MainCard";
import {
  CardContentWrapper1,
  CardContentWrapper2,
  LogoBox,
  MainCardText,
  SubCardContact,
  SubCardLink,
  SubCardText,
  SubCardTitle,
} from "./HomeStyle";
import { BoxWrapper } from "../../components/common/style";
import Logout from "../../components/common/logout";
import Password from "../../components/common/Password";
import {getUserData } from "../../components/common/common";
import { alignProperty } from "@mui/material/styles/cssUtils";


const Home = () => {
  const [username, setUsername] = useState();

  useEffect(() => {
    const userData = getUserData();
    const storedUsername = userData ? userData.firstName: null;
    setUsername(storedUsername);
  }, []);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Box sx={{ position: 'absolute', left: '20px', top: '20px' }}>
        <LogoBox component="img" src={Logo} alt="Logo" />
      </Box>

      <Box component={'div'}>
        <Box component={'div'}>
          <Button
            sx={{ position: 'absolute', right: '60px', top: '20px' }}
            onClick={handleClick}
          >
            <span className="user-name text-white">
            {username}
            </span>
          </Button>
        </Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={1}>
            <Password />
          </Box>
          <Box sx={{ p: 1}}>
            <Logout />
          </Box>
        </Popover>
      </Box>
      <Container disableGutters>
        <BoxWrapper>
          <Grid container gap={2}>
            <Grid item xs={12}>
              <MainCard>
                <CardContentWrapper1>
                  <Box>
                    <LogoBox component="img" src={Logo} alt="Logo" />
                  </Box>

                  <MainCardText variant="Body_light_16">
                    E-DocOnline facilitates the submission of your Financial
                    Data to Decision Makers
                  </MainCardText>

                  <AnimateButton>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      component={RouterLink}
                      to="/account-information"
                    >
                      Connect your bank
                    </Button>
                  </AnimateButton>
                </CardContentWrapper1>
              </MainCard>
            </Grid>

            <Grid
              container
              gap={2}
              alignItems="center"
              justifyContent="space-around"
              mt={2}
            >
              <Grid item md={5} xs={12}>
                <MainCard>
                  <CardContentWrapper2>
                    <Box>
                      <Box component="img" src={Auth} alt="Auth" />
                    </Box>

                    <SubCardText variant="Body_light_16">
                      E-DocOnline Nigeria is a Financial Data Analytics company
                      incorporated in Nigeria with RCN NO. 1835754
                    </SubCardText>
                  </CardContentWrapper2>
                </MainCard>
              </Grid>

              <Grid item md={5} xs={12}>
                <MainCard>
                  <CardContentWrapper2 sx={{ alignItems: 'start' }}>
                    <SubCardTitle variant="Body_semibold_24">
                      Do you have a question?
                    </SubCardTitle>
                    <Box style={{ display: 'flex' }}>
                      <Box>emaill :</Box>
                      <SubCardLink variant="Body_light_16" marginLeft={1}>
                        info@e-doconline.com.ng
                      </SubCardLink>
                    </Box>
                    <SubCardContact variant="Body_light_16">
                      Telephone : 09095990111{' '}
                    </SubCardContact>
                  </CardContentWrapper2>
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
        </BoxWrapper>{' '}
      </Container>
    </>
  );
};

export default Home;
