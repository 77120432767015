import React, { useCallback, useEffect, useState } from "react";

import {
  Stack,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";

import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  EmailTypography,
} from "./AuthStyle";
import { useTheme } from "@mui/material/styles";

import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input-rc-17";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export function EmailVerification() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();
  const [otp, setOtp] = useState();
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    resetTimer();
    if (!state) {
      navigate("/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  const resetTimer = function () {
    if (!timer) {
      resendOTP();
      setTimer(30);
    }
  };
  function handleClick() {
    setLoading(true);
    const obj = { email: state.email, otp: otp };
    GetAxiosInstance(`auth/verifyOtp`, 'post', obj).then((res) => {
      setLoading(false);
      if (res?.data?.codes === 200) {
        const postObj = {
          journey: 'CUSTOMER_REGISTRATION',
          eventName: 'CUSTOMER_REGISTRATION.VERIFICATION',
          status: 'SUCCESS',
          uniqueIdentifier: state.email,
          subIdentifier: '',
          data: {
            req: obj,
            res: res?.data?.message,
          },
          source: 'CONNECT',
        };
        GetAxiosInstance(`log/eventLog`, 'post', postObj);
        state['otp'] = otp;
        navigate('/email-verification-success', { state: state });
      } else {
        const postObj = {
          journey: 'CUSTOMER_REGISTRATION',
          eventName: 'CUSTOMER_REGISTRATION.VERIFICATION',
          status: 'ERROR',
          uniqueIdentifier: state.email,
          subIdentifier: '',
          data: {
            req: obj,
            res: res.response.data.message,
          },
          source: 'CONNECT',
        };
        GetAxiosInstance(`log/eventLog`, 'post', postObj);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  }
  const resendOTP = () => {
    GetAxiosInstance(`auth/sendOtp`, "post", state).then((res) => {
      if (res?.data?.codes === 200) {
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };

  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle component="form" autoComplete="off">
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Email verification
                </Typography>
                <EmailTypography>
                  We have sent a 6 digit OTP code to your email address (
                  {state?.email}). Kindly input to verify and continue
                </EmailTypography>
              </FormInfoBox>

              <Grid item xs={12}>
                <OtpInput
                  value={otp}
                  onChange={(otpNumber) => setOtp(otpNumber)}
                  numInputs={6}
                  containerStyle={{ justifyContent: "space-between" }}
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    color: "white",
                    margin: 8,
                    backgroundColor: theme.palette.primary.light,
                    padding: "10px",
                    border: "none",
                    borderRadius: 12,
                    ":hover": {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                  focusStyle={{
                    outline: "none",
                    border: "none",
                  }}
                />
              </Grid>
              {state?.flow !== "resetPassword" && (
                <Typography
                  variant="Body_light_14"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px 0 48px 0",
                  }}
                  onClick={resetTimer}
                >
                  {timer > 0 ? (
                    <>Didn't receive an OTP, resend in {timer} Sec</>
                  ) : (
                    <>Resend OTP</>
                  )}
                </Typography>
              )}

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    width: "100%",
                    margin: "30px 0",
                  }}
                  onClick={handleClick}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: "60px",
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Verify
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}
