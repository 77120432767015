import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Paper,
  Slider,
  Box,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import RoundedCheckBoxStyled from "../../components/common/formStyle/RoundedCheckBoxStyled";

import {
  RootStyle,
  FromWrapper,
  ContentStyle,
  FormStyle,
  FormInfoBox,
  FormInputWrapper,
  FormInputWrapperAccountSetup,
  SignupBoxWrapper,
  StyledLink,
  StyledAction,
  StyledTypography,
  CustomSlider,
  RadioFormControlLabelPartner,
} from "./PartnerStyle";
import { CustomPaper } from "../../components/common/style";
import {
  BpRadio,
  RadioFormControlLabel,
} from "../bank-selection/BankSelectionStyle";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const businessPartnerName = [
  { title: "Nibss Plc", id: 1 },
  { title: "One Credit", id: 2 },
  { title: "UK embassy", id: 3 },
];

const productName = [
  { title: "Statement", id: 1 },
  { title: "Transactions", id: 2 },
  { title: "Risk Indicators", id: 3 },
];

const SelectPartner = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [partnerList, setPartnerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    GetAxiosInstance("orchestrator/onlineBusinessPartners", "get").then((res) => {
      if (res?.status === 200) {
        setPartnerList(res.data);
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
    GetAxiosInstance("orchestrator/entitlement/products", "get").then((res) => {
      if (res?.status === 200) {
        setProductList(res.data?.data);
      } else {
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  }, []);
  const handleClick = async (e) => {
    e.preventDefault();
    setLoader(true);
    let payload = {};
    let selectedProductId = [];
    if (selectedProduct.length) {
      selectedProductId = selectedProduct.map((v) => {
        return { productId: v.id, permissionLevel: 2 };
      });
    }
    payload = {
      businessId: selectedPartner,
      products: selectedProductId,
    };

    await GetAxiosInstance(
      `orchestrator/entitlement/business`,
      "post",
      payload
    ).then((res) => {
      if (res && res?.data?.codes === 200) {
        setLoader(false);
        navigate("/dashboard-overview");
      } else {
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
  };
  return (
    <>
      <RootStyle>
        <FromWrapper>
          <ContentStyle>
            <FormStyle component="form" autoComplete="off">
              <Stack>
                <FormInfoBox>
                  <Typography variant="Body_semibold_32">
                    Select Partner
                  </Typography>

                  <Typography variant="Body_light_16" color="grey.lighter">
                    You are about to give access to your financial data using
                    E-DocOnline Secure service.
                  </Typography>

                  <Typography variant="Body_light_16" color="grey.lighter">
                    This means this person/organisation will have access to your
                    account information (baalance, transaction, statements).
                  </Typography>

                  <Typography variant="Body_light_16" color="grey.lighter">
                    If you no longer want this person/organisation to have
                    access, you can revoke this anytime from the View Plan page.
                  </Typography>
                </FormInfoBox>

                <FormInputWrapper>
                  <Grid item xs={12}>
                    <InputLabel>Business Partner Name</InputLabel>
                    <Autocomplete
                      id="select-business-partner"
                      options={partnerList}
                      getOptionLabel={(option) => option.companyName || ''}
                      PaperComponent={CustomPaper}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...params}
                        />
                      )}
                      onChange={(e, selectedValue) => {
                        setSelectedPartner(selectedValue?.id);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>Select Product</InputLabel>
                    <Autocomplete
                      multiple
                      id="select-product"
                      options={productList}
                      getOptionLabel={(option) => option?.name || ''}
                      PaperComponent={CustomPaper}
                      renderInput={(props) => (
                        <TextField
                          variant="filled"
                          id="filled-hidden-label-normal"
                          placeholder="Select..."
                          inputProps={{ 'aria-label': 'Without label' }}
                          hiddenLabel
                          fullWidth
                          {...props}
                        />
                      )}
                      onChange={(e, selectedValue) => {
                        setSelectedProduct(selectedValue);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} container flexDirection="column">
                    <InputLabel>Duration (Days)</InputLabel>
                    <Grid item xs sx={{ width: '100%' }}>
                      <CustomSlider
                        defaultValue={30}
                        valueLabelDisplay="on"
                        min={0}
                        max={90}
                      />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Grid item>
                        <Typography
                          variant="Body_medium_14"
                          color="grey.darker"
                        >
                          0
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="Body_medium_14"
                          color="grey.darker"
                        >
                          90
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Read"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Read"
                        control={<BpRadio />}
                        labelPlacement="end"
                        label="Read"
                      />

                      <FormControlLabel
                        value="Download"
                        control={<BpRadio />}
                        labelPlacement="end"
                        label="Download"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormInputWrapper>

                <AnimateButton>
                  <LoadingButton
                    size="large"
                    type="submit"
                    style={{ width: '100%' }}
                    variant="contained"
                    onClick={handleClick}
                    loadingIndicator={
                      <CircularProgress
                        style={{
                          padding: '60px',
                          color: theme.palette.orange.main,
                        }}
                      />
                    }
                    loading={loader}
                  >
                    Submit
                  </LoadingButton>
                </AnimateButton>
              </Stack>
            </FormStyle>
          </ContentStyle>
        </FromWrapper>
      </RootStyle>
    </>
  );
};

export default SelectPartner;
