// ----------------------------------------------------------------------

export default function FormControl(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          minHeight: 60,
          padding: theme.spacing(0, 2),
          color: theme.palette.common.white,
          ...theme.typography.Body_medium_14,

          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,

            '& svg': { color: theme.palette.common.white },
          },
        },
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.grey.light,
            ...theme.typography.Body_medium_14,
          },
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: theme.palette.grey.light,
          '&.MuiSelect-icon': {
            fontSize: 30,
            opacity: '0.4',
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: 'red',
          },
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiDayPicker-weekDayLabel': {
            color: theme.palette.common.white,
            fontWeight: 600,
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          minHeight: 60,
          ...theme.typography.boldH6,
          backgroundColor: theme.palette.primary.light,
          '&:hover': {
            backgroundColor: theme.palette.primary.lighter,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.primary.lighter,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.light,
            // color: theme.palette.common.white,
          },
          '&.Mui-error': {
            '&:after': {
              // borderBottomColor: theme.palette.orange.main,
              borderBottomColor: 'transparent',
            },
          },
        },
        hiddenLabel: {
          paddingTop: '0px !important',
        },

        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: 'transparent',
          },
          '&:after': {
            borderBottomColor: 'transparent',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'transparent',
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {},
        label: {
          fontSize: '17px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-0.22px',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey.lighter,
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey.lighter,
            },
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.light,
          borderRadius: 12,

          '& .MuiAutocomplete-tag': {
            background: theme.palette.primary.main,
            borderRadius: 4,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color: theme.palette.secondary[200],
            },
          },
        },
        popper: {
          borderRadius: 12,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          // MuiDialog: {
          //   paper: {
          backgroundColor: theme.palette.primary.light,
          //   },
          // },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '12px !important',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px',
          [theme.breakpoints.up('md')]: {
            padding: '16px',
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'red',
        },
      },
    },
  };
}
