import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";
import { Box } from '@mui/material';

// chart options
const columnChartOptions = {
  chart: {
    type: "donut",
    width: 300,
    height: 300,
  },

  labels: ["Total Expense", "Total Income"],

  legend: {
    show: true,
    position: "bottom",
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      radius: 5,
    },
    itemMargin: {
      horizontal: 25,
      vertical: 4,
    },
  },
  responsive: [
    {
      breakpoint: 450,
      chart: {
        width: 280,
        height: 280,
      },
      options: {
        legend: {
          show: true,
          position: "bottom",
        },
      },
    },
  ],
};

// ==============================|| COLUMN CHART ||============================== //

const DonutChart = ({ value, disposableIncome }) => {
  const theme = useTheme();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.main;
  const gridLine = theme.palette.secondary.chart;

  const firstCol = theme.palette.secondary.chart;
  const secondCol = theme.palette.orange.main;
  const [series, setSeries] = useState([]);
  useEffect(() => {
    if (disposableIncome.income && disposableIncome.expenses) {
      let array = [
        parseFloat(disposableIncome.expenses || 0),
        parseFloat(disposableIncome.income || 0),
      ];
      setSeries(array);
    }
  }, [disposableIncome]);

  const [options, setOptions] = useState(columnChartOptions);

  React.useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [firstCol, secondCol],
      xaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 10,
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: "18px",

                label: ` 
                ${parseFloat(value).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                `,
                color: "#FFFFFF",
                formatter: function (w) {
                  return "";
                },
              },
            },
          },
        },
      },
      legend: {
        labels: {
          colors: "grey.500",
        },
      },
      stroke: {
        colors: "transparent",
      },

      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
    }));
  }, [primary, darkLight, firstCol, secondCol, gridLine, disposableIncome]);

  return (
    <Box component={'div'} id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={450}
      />
    </Box>
  );
};

export default DonutChart;
