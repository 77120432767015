import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Divider, Stack, Typography } from '@mui/material';
import MainCard from '../../components/common/MainCard';
import moment from 'moment';

const chartOptionsData = {
  chart: {
    type: 'line',
    height: 450,
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
  },
  stroke: {
    width: 2,
    colors: ['#FF4343', '#1CB110'], // Red for the first series, green for the second
  },
  markers: {
    size: 6,
    colors: ['#FF4343', '#1CB110'], // Red for the first series, green for the second
  },
  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: '#ffffff',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#ffffff',
      },
    },
    title: {
      text: '',
    },
  },
  tooltip: {
    theme: 'dark',
  },
  grid: {
    borderColor: '#555555',
  },
  legend: {
    show: true,
    position: 'right',
    horizontalAlign: 'center',
    offsetY: 0,
    labels: {
      colors: '#ffffff',
    },
    markers: {
      width: 12,
      height: 12,
      radius: 12,
    },
  },
};

const TransactionAmountSpikeChart = ({ risk_spikes }) => {
  const [series, setSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsData);

  useEffect(() => {
    const transactionList = risk_spikes?.transaction_list || [];

    const highSpikeData = [];
    const lowSpikeData = [];
    const categories = [];

    transactionList.forEach((transaction) => {
      const formattedDate = moment(transaction.booking_date_time).format(
        'MMM YYYY'
      );
      categories.push(formattedDate);

      if (transaction.high === 'yes') {
        highSpikeData.push(transaction.transaction_amount);
      } else {
        highSpikeData.push(0); // To maintain alignment
      }

      if (transaction.low === 'yes') {
        lowSpikeData.push(transaction.transaction_amount);
      } else {
        lowSpikeData.push(0); // To maintain alignment
      }
    });

    const seriesData = [
      {
        name: 'High Spike Transactions',
        data: highSpikeData,
        color: '#FF4343',
      },
      {
        name: 'Low Spike Transactions',
        data: lowSpikeData,
        color: '#1CB110',
      },
    ];

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories,
      },
    }));

    setSeries(seriesData);
  }, [risk_spikes?.transaction_list]);

  return (
    <>
      <MainCard
        id="chart"
        sx={{
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          padding: '10px 16px 0px !important',
        }}
      >
        <Box mb={2}>
          <Typography variant="Body_semibold_20" color="orange.main">
            Transaction Amount Spikes
          </Typography>
        </Box>

        <ReactApexChart
          options={chartOptions}
          series={series}
          type="line"
          height={450}
        />
      </MainCard>
      <MainCard
        sx={{
          backgroundColor: '#002030',
          borderTopLeftRadius: '0px !important',
          borderTopRightRadius: '0px !important',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          gap={3}
          flexWrap="wrap"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: '#ffffff1a' }}
            />
          }
        >
          <Typography variant="Body_medium_14" fontWeight={300}>
            High Spike:{' '}
            <Box component="strong">
              ₦{risk_spikes?.max_amount.toLocaleString()}
            </Box>
          </Typography>

          <Typography variant="Body_medium_14" fontWeight={300}>
            Average Spike:{' '}
            <Box component="strong">
              ₦{risk_spikes?.mean_amount.toLocaleString()}
            </Box>
          </Typography>

          <Typography variant="Body_medium_14" fontWeight={300}>
            Median Spike:{' '}
            <Box component="strong">
              ₦{risk_spikes?.median_amount.toLocaleString()}
            </Box>
          </Typography>
        </Stack>
      </MainCard>
    </>
  );
};

export default TransactionAmountSpikeChart;
