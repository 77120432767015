import { Box, Typography, Button, Grid, useTheme } from "@mui/material";
import React from "react";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
} from "../Auth/AuthStyle";
import { Link as RouterLink } from "react-router-dom";

import SuccessLogo from "../../assets/Success.svg";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { FillButton, OutlineButton } from "../../components/common/style";
import { PlanDescCommon, PlanStepTitle } from "./PlansStyle";

const CancellationComplete = (props) => {
  const theme = useTheme();
  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="box"
            style={{
              maxWidth: 670,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={SuccessLogo}
                height={85}
                width={85}
                mb={5}
              />

              <PlanStepTitle>Cancellation complete</PlanStepTitle>

              <Box>
                <PlanDescCommon>
                  You are no longer providing us access to your banking data and
                  your service has been reduced to the basic plan.{" "}
                </PlanDescCommon>
              </Box>

              <Box
                textAlign="center"
                my={5}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography
                  component={RouterLink}
                  to="/home"
                  sx={{
                    color: theme.palette.orange.main,
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Go to your connected account
                </Typography>
                <Typography
                  component={RouterLink}
                  to="/home"
                  sx={{
                    color: theme.palette.orange.main,
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  View updated plan
                </Typography>
              </Box>
            </Box>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
};

export default CancellationComplete;
