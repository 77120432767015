import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logo from "../../assets/Logo.svg";
import Logout from "../common/logout";
// ----------------------------------------------------------------------

SideMenuIcon.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function SideMenuIcon({ onOpenSidebar }) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: theme.spacing(1, 2),
        }}
      >
        <Box
          component="img"
          src={Logo}
          alt=""
          sx={{ height: "38px", width: "94px", display: { lg: "none" } }}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              position: "absolute",
              right: "150px",
              top: "10px",
              display: { lg: "none" },
            }}
          >
            <KeyboardArrowDownIcon
              sx={{ fill: theme.palette.orange.main }}
              fontSize="large"
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
