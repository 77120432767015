import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideMenuIcon from "../sidebar/SideMenuIcon";
import Sidebar from "../sidebar/Sidebar";
import { Box } from "@mui/material";
import Logout from "../common/logout";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  flexDirection: "column",

  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingTop: 80,
  },
}));

// ----------------------------------------------------------------------

export function MainLayout() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RootStyle>
        <SideMenuIcon onOpenSidebar={() => setOpen(true)} />
        <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <Box sx={{ position: "absolute", right: "20px", top: "20px" }}>
          <Logout />
        </Box>
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
