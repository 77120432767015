import {
  Button,
  ButtonGroup,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  MainCardStyled,
  PageWrapper,
  StyledTableCellData,
  StyledTableCellDataItem,
  TableCellHead,
  TableWrapper,
} from "./CategoryInsightsStyle";
import DashboardUINav from "../../components/Navbar/DashboardUINav/DashboardUINav";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { getUserData } from "../../components/common/common";
import GetAxiosInstance from "../../components/common/api";
import Loader from "../../components/common/Loader";
import moment from "moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
function createData(Item, Percentage, Amount) {
  return { Item, Percentage, Amount };
}
const monthRange = [
  
  {
    title: "1 Month",
    value: 1,
  },
  {
    title: "3 Months",
    value: 3,
  },
  {
    title: "6 Months",
    value: 6,
  },
  {
    title: "9 Months",
    value: 9,
  },
  {
    title: "12 Months",
    value: 12,
  },
];
const ExpandList = ({ updateQuantity }) => {
  let { state } = useLocation();
  const [transactionGroup, setTransactionGroup] = useState([]);
  const [loader, setLoader] = useState(false);
  let [nameSearch, setNameSearch] = useState("");
  let [filterValue, setFilterValue] = useState([]);
  let [dashboardData, setDashboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [offSet, setOffSet] = useState();
  const [consent, setConsent] = useState();
  const [endDate, setEndDate] = useState([]);
  const [selectedType, setSelectedType] = useState(state?.selectedType || 'tran_amount_total_for_period');

  useEffect(() => {
    let responseValue = [];
    if (nameSearch) {
      responseValue = transactionGroup.filter((item) => {
        return (
          (item.category_lvl1 &&
            item?.category_lvl1
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase())) ||
          (item.category_lvl2 &&
            item?.category_lvl2
              .toLowerCase()
              .includes(nameSearch.toLocaleLowerCase()))
        );
      });
    } else {
      responseValue = transactionGroup;
    }
    setOffSet(0 * 10);

    setFilterValue(responseValue);
  }, [nameSearch, transactionGroup]);
  // const offSet = currentPage * 10;
  useEffect(() => {
    setOffSet(currentPage * 10);
  }, [currentPage]);
  useEffect(async () => {
    let endDate = moment().format("YYYY-MM-DD");

    setLoader(true);
    await GetAxiosInstance(`orchestrator/get-consent`, "get").then((res) => {
      if (res.data?.codes === 200) {
        // setLoader(false);
        // if (res.data?.data.length) {
        let accountNumberList = res?.data?.data.consents.map((v) => {
          return { title: v.accountNumber, value: v.accountNumber };
        });

        let selectedProduct = res?.data?.data?.consents.find(
          (item) => item.accountNumber === state?.selectAccount
        );

        endDate = selectedProduct?.consent?.data?.dateTo
          ? moment(selectedProduct?.consent?.data?.dateTo).format("YYYY-MM-DD")
          : "";

        setEndDate(endDate);
        setConsent(res.data?.data?.consents);
        // }
        setConsent(res.data?.data?.consents);
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || "Something went wrong!"
        );
      }
    });

    let dataArray = [];
    let userData = getUserData();
    let data = state?.selectedDateRang.length;
    monthRange.forEach((v, index) => {
      if (data >= v.value) {
        dataArray.push(v);
      }
    });

    let dashboardPayload = {
      userId: userData?.id,
      date_to: state?.selectedEndDate ? state?.selectedEndDate : endDate,
      n_months: state?.selectMonth,
      account_id: state?.selectAccount,
      accountType: state?.accountType,
    };
    GetAxiosInstance(
      "orchestrator/get-dashboard",
      "post",
      dashboardPayload
    ).then((res) => {
      setLoader(false);
      if (res?.data?.codes === 200) {
        setDashboardData(res?.data?.data?.monthly);
      } else {
        setDashboardData([]);
        setLoader(false);
        toast.error(res?.response?.data?.message || res.message);
      }
    });
    let payload = {
      user_id: userData?.id,
      date_to: state?.selectedEndDate ? state?.selectedEndDate : endDate,
      n_months: state?.selectMonth,
      account_id: state?.selectAccount,
      tran_threshold: 0,
    };
    GetAxiosInstance(
      "orchestrator/get-category-insights",
      "post",
      payload
    ).then((res) => {
      if (
        res?.data?.codes === 200 &&
        res?.data?.data?.transaction_groups.length &&
        res?.data?.data?.disposable_income &&
        res?.data?.data?.monthly_transactions.length
      ) {
        setLoader(false);
        let result = res.data.data;
        let transactions = result?.transaction_groups.filter((item) => {
          return (
            item.category_lvl1 !== null &&
            item?.tran_amount_monthly_average !== null &&
            item.category_lv2 !== null &&
            item.aggregation_lvl === 2
          );
        });
        setTransactionGroup(transactions);
      } else {
        setTransactionGroup([]);
      }
      setLoader(false);
      toast.error(res?.response?.data?.message || res.message);
    });
  }, []);
  const theme = useTheme();
  return (
    <>
      <DashboardUINav dashboardData={dashboardData} />

      <PageWrapper mt={5}>
        <MainCardStyled>
          <Stack
            my={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="start" gap={1}>
              <Box component={RouterLink} to="/category-insights">
                <ArrowBackIcon
                  sx={{
                    fill: (theme) => theme.palette.common.white,
                  }}
                />
              </Box>
              <Typography variant="Body_medium_18">
                Transaction Groups
              </Typography>
            </Stack>
            <TextField
              variant="filled"
              id="filled-hidden-label-normal"
              placeholder="Search for a bank..."
              autoComplete="off"
              hiddenLabel
              // fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setNameSearch(e.currentTarget.value);
              }}
            />
          </Stack>

          <TableContainer>
            <Table
              stickyHeader
              sx={{
                minWidth: 350,
              }}
            >
              {loader ? (
                <Loader />
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCellHead>Type</TableCellHead>
                      <TableCellHead>Category</TableCellHead>
                      <TableCellHead>Subcategory</TableCellHead>
                      <TableCellHead>Amount</TableCellHead>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterValue
                      .slice(offSet, offSet + 10)
                      .map((row, index) => (
                        <TableRow hover key={index}>
                          <StyledTableCellDataItem>
                            {row.tran_type}
                          </StyledTableCellDataItem>
                          <StyledTableCellData>
                            {row?.category_lvl1}
                          </StyledTableCellData>
                          <StyledTableCellData>
                            {row?.category_lvl2}
                          </StyledTableCellData>
                          <StyledTableCellData>
                                  ₦{' '}
                                  {(row?.[selectedType] || 0).toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}{' '}
                                </StyledTableCellData>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 3.5,
            }}
          >
            <Box width={"130px"} />
            <Box width={"110px"}>
              <Button
                fullWidth
                size="large"
                sx={{
                  background: theme.palette.primary.light,
                  px: 5,
                }}
              >
                {" "}
                Next{" "}
              </Button>
            </Box>
            <Stack direction="row" alignItems="center" gap={1}>
              <Increment
                quantity={1}
                updateQuantity={updateQuantity}
                setCurrentPage={setCurrentPage}
                pageCount={parseInt(filterValue.length / 10)}
              />{" "}
              of {parseInt(filterValue.length / 10)}
            </Stack>
          </Box>
        </MainCardStyled>
      </PageWrapper>
    </>
  );
};

const Increment = ({
  itemId,
  quantity,
  updateQuantity,
  setCurrentPage,
  pageCount,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(quantity);

  const incrementHandler = () => {
    setValue(value - 1);
    // updateQuantity(itemId, value - 1);
  };

  const decrementHandler = () => {
    if (pageCount > value) {
      setValue(value + 1);
    }
    // updateQuantity(itemId, value + 1);
  };
  useEffect(() => {
    setCurrentPage(value);
  }, [value]);

  return (
    <ButtonGroup
      size="large"
      variant="text"
      color="inherit"
      sx={{
        border: "none",
        backgroundColor: "transparent",
      }}
    >
      <Button
        key="three"
        disabled={value <= 1}
        onClick={incrementHandler}
        sx={{
          pr: 1,
          pl: 1,
          minWidth: "0px !important",
          backgroundColor: theme.palette.primary.light,
          borderRight: "none !important",

          "&:hover": {
            backgroundColor: "transparent ",
          },
        }}
      >
        <ChevronLeftIcon
          fontSize="medium"
          sx={{ fill: theme.palette.grey.lighter }}
        />
      </Button>
      <Button
        key="two"
        sx={{
          pl: 3,
          pr: 3,
          backgroundColor: theme.palette.primary.light,
          borderRight: "none !important",

          "&:hover": {
            backgroundColor: "transparent ",
          },
        }}
      >
        {value}
      </Button>
      <Button
        key="one"
        onClick={decrementHandler}
        sx={{
          pl: 1,
          pr: 1,
          minWidth: "0px !important",
          backgroundColor: theme.palette.primary.light,
          borderRight: "none !important",

          "&:hover": {
            backgroundColor: "transparent ",
          },
        }}
      >
        <ChevronRightIcon
          fontSize="medium"
          sx={{ fill: theme.palette.grey.lighter }}
        />
      </Button>
    </ButtonGroup>
  );
};

Increment.propTypes = {
  itemId: PropTypes.number,
  quantity: PropTypes.number,
  updateQuantity: PropTypes.func,
};

export default ExpandList;
