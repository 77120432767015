import { styled } from "@mui/material/styles";
import React from "react";
import { Outlet } from "react-router-dom";
// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export function NoLayout() {
  return (
    <>
      <RootStyle>
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
