import React, { useEffect, useState } from "react";

import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  StyledTypography,
  StyledLink,
  FormInputWrapper,
  SignupBoxWrapper,
  StyledAction,
  NoAccountText,
} from "./AuthStyle";
import { toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import GetAxiosInstance from "../../components/common/api";
import { LoadingButton } from "@mui/lab";
import decodeJwt from "jwt-decode";
import { getUserData, removeUserData } from "../../components/common/common";

// ----------------------------------------------------------------------

export function Login() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState();
  const formSchema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  useEffect(() => {
    let existsUSerData = getUserData();
    if (existsUSerData) {
      removeUserData();
    }
    localStorage.removeItem('deepLinkId');
  }, []);
  const onSubmit = (data) => {
    setLoading(true);
    GetAxiosInstance("auth/login", "post", {
      email: data.email,
      password: data.password,
    }).then((res) => {
      if (res?.data?.codes === 200) {
        let decode = decodeJwt(res?.data?.data?.accessToken);
        let obj = {
          accessToken: res?.data?.data?.accessToken,
          refreshToken: res?.data?.data?.refreshToken,
          userType: "nonGuest",
        };
        obj = { ...obj, ...decode };
        localStorage.setItem("userData", JSON.stringify(obj));

        GetAxiosInstance(`orchestrator/get-consent`, "get").then((res) => {
          if (res.data?.codes === 200) {
            let consentData =
              res.data?.data.consents.length &&
              res.data?.data.consents.find((item) => {
                return item.dashboardAvailable;
              });
            if (consentData) {
              navigate("/dashboard-overview");
            } else {
              navigate("/home");
            }
            toast.success("Login successfully");
            setLoading(false);
          } else {
            toast.error(res?.response?.data?.message || res.message);
            setLoading(false);
          }
        });
      }
      setLoading(false);
      toast.error(res?.response?.data?.message || res.message);
    });
  };
  return (
    <>
      <RootStyle>
        <FromWrapper
          sx={{
            height: { xs: "70vh" },
          }}
        >
          <SignupBoxWrapper>
            <NoAccountText>I don't have an account.</NoAccountText>
            <StyledAction component={RouterLink} to="/signup">
              Create Account
            </StyledAction>
          </SignupBoxWrapper>

          <ContentStyle>
            <FormStyle
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack>
                <FormInfoBox>
                  <StyledTypography variant="Body_semibold_20">
                    Welcome back!
                  </StyledTypography>
                  <Typography variant="Body_semibold_24">
                    Please Log in...
                  </Typography>
                </FormInfoBox>
                {/* <form> */}
                <FormInputWrapper>
                  <Grid item xs={12}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      variant="filled"
                      type="email"
                      placeholder="Enter email address"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ "aria-label": "Without label" }}
                      hiddenLabel
                      {...register("email")}
                      helperText={errors.email && errors.email.message}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>Enter Password</InputLabel>
                    <TextField
                      variant="filled"
                      type="password"
                      placeholder="Enter your password"
                      id="filled-hidden-label-normal"
                      fullWidth
                      inputProps={{ "aria-label": "Without label" }}
                      hiddenLabel
                      {...register("password")}
                      helperText={errors.password && errors.password.message}
                    />
                  </Grid>
                </FormInputWrapper>

                <AnimateButton>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ width: "100%" }}
                    // sx={{
                    //   color: "orange.main",
                    // }}
                    // onClick={onSubmit}
                    loadingIndicator={
                      <CircularProgress
                        style={{
                          padding: "60px",
                          color: theme.palette.orange.main,
                        }}
                      />
                    }
                    loading={loading}
                  >
                    Login
                  </LoadingButton>
                </AnimateButton>

                {/* </form> */}
                <StyledLink
                  underline="none"
                  variant="Body_medium_16"
                  component={RouterLink}
                  to="/forgot-password"
                >
                  I forgot my password
                </StyledLink>

                {/* <AnimateButton>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      background: "#01131C",
                      color: theme.palette.orange.main,
                      width: "100%",
                      marginTop: "2rem",
                    }}
                    component={RouterLink}
                    to="/guest-login"
                    loadingIndicator={
                      <CircularProgress
                        style={{
                          padding: "60px",
                          color: theme.palette.orange.main,
                        }}
                      />
                    }
                  >
                    guest Login
                  </LoadingButton>
                </AnimateButton> */}
              </Stack>
            </FormStyle>
          </ContentStyle>
        </FromWrapper>
      </RootStyle>
    </>
  );
}
