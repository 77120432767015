const mixins = {
  flexy: {
    display: 'flex',
  },

  flexyCol: {
    display: 'flex',
    flexDirection: 'column',
  },

  flexyCenterItem: {
    display: 'flex',
    alignItems: 'center',
  },

  flexyItemStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },

  flexyCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  flexyCenterCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  flexyItemCenterCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  flexyBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  flexyColBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default mixins;
