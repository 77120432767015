import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";
import { Box } from '@mui/material';

// chart options
const columnChartOptions = {
  chart: {
    type: 'bar',
    height: 450,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: '$ (thousands)',
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val}`;
      },
    },
  },
  legend: {
    show: true,
    position: 'top',
    offsetX: 10,
    offsetY: 10,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 16,
      height: 16,
      radius: 5,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 8,
    },
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: true,
        },
      },
    },
  ],
};

// ==============================|| COLUMN CHART ||============================== //

const ApexColumnChart = ({ apexIncomeData, apexExpenseData, apexMonth }) => {
  const theme = useTheme();

  const { primary } = theme.palette.text;
  const darkLight = theme.palette.dark.main;

  const gridLine = theme.palette.secondary.chart;

  const firstCol = theme.palette.orange.main;
  const secondCol = theme.palette.secondary.chart;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(columnChartOptions);

  useEffect(() => {
    // if (apexExpenseData.length && apexIncomeData.length) {
    let array = [
      {
        name: 'Total Income',
        data: apexIncomeData,
      },
      {
        name: 'Total Expense',
        data: apexExpenseData,
      },
    ];
    setSeries(array);
    // }
  }, [apexIncomeData, apexExpenseData, apexMonth]);

  React.useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [firstCol, secondCol],
      xaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
        categories: apexMonth,
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },

      grid: {
        borderColor: gridLine,
        border: '2px solid',
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      tooltip: {
        // enabled: false,
        theme: 'dark',
      },
      legend: {
        labels: {
          colors: 'grey.500',
        },
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
    }));
  }, [primary, darkLight, firstCol, secondCol, gridLine, apexMonth]);

  return (
    <Box component={'div'} id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
      />
    </Box>
  );
};

export default ApexColumnChart;
