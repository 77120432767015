import { Box, Dialog, Drawer, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../assets/Logo.svg";
import Lock from "../../assets/Lock.svg";
import Scrollbar from "../common/Scrollbar";

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 390;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const isDesktop = useResponsive("up", "lg");

  const theme = useTheme();

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            px: 5,
            py: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ pb: 15, display: { xs: "none", lg: "block" } }}>
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              width={144}
              height={58}
            />
          </Box>

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="Body_light_16" color="grey.darker">
            E-DocOnline service submits financial
            data in a structured and secure way as
            part of your application process.
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <Box component="img" src={Lock} alt="Logo" />
            <Typography
              variant="Body_light_16"
              fontWeight="400"
              color="orange.main"
              textAlign="center"
              sx={{ textTransform: "uppercase" }}
            >
              secure connection
            </Typography>
          </Box>
        </Box>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Dialog
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          anchor="top"
          PaperProps={{
            sx: {
              width: "100%",
              bgcolor: theme.palette.primary.light,
            },
          }}
        >
          {renderContent}
        </Dialog>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: theme.palette.primary.light,
              border: "none",
              borderRadius: 0,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
