import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/common/MainCard";
import {
  CardInfoSubTitle,
  CardInfoTitle,
  CardInfoWrapper,
  PageBoxWrapper,
} from "./PlansStyle";
import { Link as RouterLink } from "react-router-dom";
import Bank1 from "../../assets/Bank1.png";
import Bank2 from "../../assets/Bank2.png";
import Bank3 from "../../assets/Bank3.png";
import Bank4 from "../../assets/Bank4.png";
import {
  BoxWrapper,
  FillButton,
  OutlineButton,
  StyledDivider,
} from "../../components/common/style";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import ManagePlans from "./ManagePlans";
import GetAxiosInstance from "../../components/common/api";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
//

function CircularProgressWithLabel1({ value, ...others }) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress variant="determinate" value={value} {...others} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="Body_semibold_32" component="div" color="">
          10
        </Typography>
        <Typography variant="Body_light_12">days left</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel1.propTypes = {
  value: PropTypes.number,
};

//
function CircularProgressWithLabel2({ value, ...others }) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress variant="determinate" value={value} {...others} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="Body_semibold_32" component="div" color="">
          76
        </Typography>
        <Typography variant="Body_light_12">days left</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel2.propTypes = {
  value: PropTypes.number,
};

//
function CircularProgressWithLabel3({ value, ...others }) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress variant="determinate" value={value} {...others} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="Body_semibold_32" component="div" color="">
          32
        </Typography>
        <Typography variant="Body_light_12">days left</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel3.propTypes = {
  value: PropTypes.number,
};

//
const Plans = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [consent, setConsent] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(async () => {
    setLoader(true);
    await GetAxiosInstance(`orchestrator/get-consent`, "get").then((res) => {
      if (res.data?.codes === 200) {
        if (res.data?.data?.consents.length) {
          setConsent(res.data?.data?.consents);
          setLoader(false);
        }
      } else {
        setLoader(false);
        toast.error(
          res?.response?.data?.message || res.message || "Something went wrong!"
        );
      }
    });
  }, []);
  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      {!loader ? (
        <Box sx={{ mx: { sm: 5 } }}>
          <BoxWrapper>
            <MainCard>
              <CardInfoWrapper>
                <Stack direction="column" alignItems="flex-start">
                  <CardInfoTitle>Manage Plan</CardInfoTitle>
                  <CardInfoSubTitle color="grey.darker">
                    We, E-doconline, have your permission to access your data to
                    offer financial services.
                  </CardInfoSubTitle>
                </Stack>

                <AnimateButton>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    component={RouterLink}
                    to="/select-partner"
                  >
                    Add an account
                  </Button>
                </AnimateButton>
              </CardInfoWrapper>
            </MainCard>

            {/*  */}
            <Box mt={4}>
              <Grid container spacing={2} alignItems="center">
                {/*  */}

                {consent?.map((v, idnex) => (
                  <Grid item md={4} xs={12} key={idnex}>
                    <MainCard sx={{ padding: 1 }}>
                      {/*  */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                          mb: 5,
                        }}
                      >
                        {/* <Box
                    component="img"
                    src={Bank4}
                    alt="1"
                    height={34}
                    width={34}
                  /> */}
                        {/* <Typography variant="Body_semibold_18">
                    Guaranty Trust Bank
                  </Typography> */}
                      </Box>

                      {/*  */}
                      <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <CircularProgressWithLabel1
                            value={90}
                            sx={{ color: '#FF5959' }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle"
                            color="grey.main"
                            alignItems="center"
                          >
                            Status{' '}
                            <span
                              style={{
                                ...theme.typography.Body_semibold_16,
                                color: '#40EA8F',
                                fontWeight: '600',
                              }}
                            >
                              Active
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      {/*  */}
                      <Grid container direction="column" mt={4}>
                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="Body_light_16">
                                Account Type
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="Body_semibold_16">
                                Current Account
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledDivider />

                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="Body_light_16">
                                Account number
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="Body_semibold_16">
                                {v.accountNumber}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledDivider />

                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="Body_light_16">
                                Access gained
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="Body_semibold_16">
                                31 Jun 2022
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledDivider />

                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="Body_light_16">
                                Last access refreshed
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="Body_semibold_16">
                                20 May 2022
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledDivider />

                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="Body_light_16">
                                Access expires
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="Body_semibold_16">
                                20 May 2022
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledDivider />
                      </Grid>

                      {/*  */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                      >
                        <AnimateButton>
                          <OutlineButton
                            size="large"
                            onClick={handleClickOpenDialog}
                            variant="contained"
                            sx={{
                              color: theme.palette.orange.main,
                              ...theme.typography.Body_medium_14,
                              width: 'auto !important',
                            }}
                          >
                            Cancel Consent
                          </OutlineButton>{' '}
                        </AnimateButton>

                        <AnimateButton>
                          <FillButton
                            size="large"
                            component={RouterLink}
                            to="/select-partner"
                            variant="contained"
                            sx={{
                              color: theme.palette.common.white,
                              ...theme.typography.Body_medium_14,
                              width: 'auto !important',
                            }}
                          >
                            Manage Permission
                          </FillButton>{' '}
                        </AnimateButton>
                      </Stack>
                    </MainCard>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/*  */}
          </BoxWrapper>
          <ManagePlans open={open} handleCloseDialog={handleCloseDialog} />
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Plans;
