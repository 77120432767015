import { merge } from 'lodash';
import Button from './Button';
import Dialog from './Dialog';
import FormControl from './FormControl';
import Tooltip from './Tooltip';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    FormControl(theme),
    Tooltip(theme),
    Button(theme),
    Dialog(theme)
  );
}
