import { CardContent, Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardContentWrapper1 = styled(CardContent)(({ theme }) => ({
  height: "442px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
}));

export const CardContentWrapper2 = styled(CardContentWrapper1)(({ theme }) => ({
  height: "auto",

  [theme.breakpoints.up("sm")]: {
    height: "239px",
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  height: "44px",
  width: "128px",
}));

export const MainCardText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(9.6, 0, 6.3, 0),
  textAlign: "center",
  color: theme.palette.common.white,
}));

export const SubCardText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.grey.darker,
  marginTop: theme.spacing(3.8),
}));

export const SubCardTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(5.125),
}));

export const SubCardLink = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  textDecoration: "underline",
  color: theme.palette.grey.darker,
  cursor: "pointer",
}));

export const SubCardContact = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.darker,
}));

export const CostForService = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: `2px solid ${theme.palette.primary.bg}`,
  borderRadius: "12px",
  padding: theme.spacing(1.5, 2),
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  minWidth: "140px",

  "@media (max-width:380px)": {
    minWidth: "100px",
  },
}));
